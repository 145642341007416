import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import AddIcon from "@mui/icons-material/Add";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  Typography,
  Button,
  Box,
  DialogActions,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import EditIcon from "../../assets/icons/editicon";
import EditIconDisabled from "../../assets/icons/editiconDisabled";
import DeleteIcon from "../../assets/icons/deleteicon";
import DeleteIconDisabled from "../../assets/icons/deleteiconDisabled";
import ViewIcon from "../../assets/icons/viewicon";
import ViewIconDisabled from "../../assets/icons/viewiconDisabled";
import PlayIcon from "../../assets/icons/playicon";
import PauseIcon from "../../assets/icons/pauseicon";
import PauseIconDisabled from "../../assets/icons/pauseiconDisabled";
import Styles from "./Table.module.scss";
import Filter from "../Filter";
import TableTopbar from "../TableTopbar";
import { Link } from "react-router-dom";
import moment from 'moment';
import { imageBaseUrl, imageLiveBaseUrl } from "../../config";
import { AD_SPACES } from "../../utils";
import { useSelector } from "react-redux";
import Pagination from "../Pagination";
import { useFetchAdminUserQuery } from '../../features/api/userApi';
import { useFetchCategoriesQuery } from '../../features/api/categoryApi';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const currentURL = window.location.href;
const imageUrl = currentURL.includes('nattuvartha') ? imageLiveBaseUrl : imageBaseUrl;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: 'rgba(47, 43, 61, 0.68)',
  fontFamily: 'Public Sans',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '15px',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TableComponent({
  title,
  defaultTitle,
  headers,
  data,
  metaData,
  rows = 5,
  buttonContent = "",
  view = false,
  add = true,
  addUrl = '/create',
  viewUrl = '/',
  navigateFromBase = false,
  buttonStatus = true,
  actions = {},
  filter = false,
  filterData = {},
  permissionPath = 'dashboard',
  currentPage,
  onPaginationChange,
  onSearch,
  onPaginationCountChange,
  filterValues,
  setFilterValues,
  filterSearch,
}) {
  const currentPath = window.location.pathname;
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rows);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data || []);
  const userData = useSelector(state => state?.auth?.user);

  const { data: categoryData } = useFetchCategoriesQuery();
  const { data: adminUserData } = useFetchAdminUserQuery();

  useEffect(() => {
    setFilteredData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (userData?.roleData[`${permissionPath}_viewOwn`]) {
      setFilteredData(filteredData?.filter((item) => {
        return item?.author_id === userData?._id
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData, userData?.roleData[`${permissionPath}_viewOwn`]]);

  const handleChangeRowsPerPage = (event) => {
    onPaginationCountChange(event.target.value);
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleClose = () => setOpen(false);

  const handleConfirmation = () => {
    handleClose();
    const deleteAction = actions.filter((action) => action.type === 'delete')[0];
    deleteAction.onClick(deleteId);
  }

  const handleClickDelete = (id) => {
    setOpen(true);
    setDeleteId(id);
  }

  const handleIconClick = (action, row) => {
    switch (action.type) {
      case "delete":
        handleClickDelete(row?._id);
        break;
      case "playPause":
        action.onClick(row?._id, !row.status);
        break;
      case "viewNews":
        action.onClick(row?.category?.slug, row?.subcategory?.slug || 'all', row?.slug);
        break;
      default:
        action.onClick(row?._id);
    }
  }
  
  return (
    <div>
      <div className={Styles.tableHeader}>
        <div className={defaultTitle ? Styles.title2 : Styles.title}>
          <p>{title}</p>
        </div>
        {buttonStatus && (
          <div className={Styles.viewAll}>
            {view && (
              <div className={Styles.viewButton}>
                <Link
                  to={viewUrl}
                  className={Styles.viewButton}
                  state={currentPath.includes('schedulePost') ? { 'enableDateTimePicker': true } : {}}
                >
                  <RemoveRedEyeOutlinedIcon
                    sx={{ fontSize: 20, color: "#ffffff" }}
                  />
                  <span>{buttonContent}</span>
                </Link>
              </div>
            )}
            {(add && userData?.roleData[`${permissionPath}_create`]) && (
              <Link
                to={navigateFromBase ? `${addUrl}` : `${currentPath}${addUrl}`}
                className={Styles.viewButton}
                state={currentPath.includes('schedulePost') ? { 'enableDateTimePicker': true } : {}}
              >
                <AddIcon sx={{ fontSize: 20, color: "#ffffff" }} />
                <span>{buttonContent}</span>
              </Link>
            )}
          </div>
        )}
      </div>
      {filter && (
        <Filter
          filterData={filterData}
          adSpaces={AD_SPACES}
          authors={adminUserData?.data}
          categories={categoryData?.data}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          filterSearch={filterSearch}
        />
      )}
      <div className={Styles.tableOuter}>
        <TableTopbar
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onSearch={onSearch}
        />
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
          {data ? <Table sx={{ minWidth: 600 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <StyledTableCell key={index}>
                    {header.title}
                  </StyledTableCell>
                ))}
                {actions.length && <StyledTableCell sx={{ width: '100px' }}>ACTIONS</StyledTableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => (
                  <StyledTableRow key={index}>
                    {headers.map((header) => {
                      switch (header.type) {
                        case "id":
                          return (
                            <StyledTableCell align="left" key={header.key}>
                              {index + 1}
                            </StyledTableCell>
                          );
                        case "text":
                          return (
                            <StyledTableCell align="left" key={header.key}>
                              {header.key === "page_type" ? 'Custom' : row?.[header.key] || '----'}
                            </StyledTableCell>
                          );
                        case "date":
                          return (
                            <StyledTableCell align="left" key={header.key}>
                              {moment(row?.[header.key]).format("DD/MM/YYYY") || '----'}
                            </StyledTableCell>
                          );
                        case "role":
                          return (
                            <StyledTableCell align="left" key={header.key}>
                              {row?.[header.key]?.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (str) => str.toUpperCase()) || '----'}
                            </StyledTableCell>
                          );
                        case "comment":
                          return (
                            <StyledTableCell align="left" key={header.key}>
                              <div dangerouslySetInnerHTML={{ __html: row?.[header.key] }}></div>
                            </StyledTableCell>
                          );
                        case "parent":
                          return (
                            <StyledTableCell align="left" key={header.type}>
                              {row?.category?.name || '----'}
                            </StyledTableCell>
                          );
                        case "author":
                          return (
                            <StyledTableCell align="left" key={header.type}>
                              {row?.author?.name || '----'}
                            </StyledTableCell>
                          );
                        case "category":
                          return (
                            <StyledTableCell align="left" key={header.type}>
                              <span className={Styles.backGroundText}>
                                {row?.subcategory?.name || '----'}
                              </span>
                            </StyledTableCell>
                          );
                       
                       
                        case "image":
                          return (
                            <StyledTableCell align="left" key={header.key}>
                              <img className={Styles.imagethumb} src={`${imageUrl}${row?.[header.key]}`} alt={row?.id} />
                            </StyledTableCell>
                          );
                        case "adImage":
                          return (
                            <StyledTableCell align="left" key={header.key}>
                              <img className={Styles.adImage} src={`${imageUrl}${row?.[header.key]}`} alt={row?.id} />
                            </StyledTableCell>
                          );
                        case "color":
                          return (
                            <StyledTableCell align="left" key={header.key}>
                              <SquareRoundedIcon
                                sx={{
                                  fontSize: 20,
                                  color: row?.[header.key],
                                }}
                              />
                            </StyledTableCell>
                          );
                        case "imageText":
                          return (
                            <StyledTableCell align="left" key={header.key}>
                              <div className={Styles.posts}>
                                <img
                                  src={`${imageUrl}${row?.main_image}` || row?.main_image}
                                  alt={row?.id}
                                />
                                <p className={Styles.postTitle}>{row?.title}</p>
                              </div>
                            </StyledTableCell>
                          );
                        case "visibility":
                          return (
                            <StyledTableCell align="left" key={header.key}>
                              {row?.[header.key] === "show" ? (
                                <RemoveRedEyeOutlinedIcon
                                  sx={{ fontSize: 20, color: "#0BA823" }}
                                />
                              ) : (
                                <RemoveRedEyeOutlinedIcon
                                  sx={{ fontSize: 20, color: "#E04C5E" }}
                                />
                              )}
                            </StyledTableCell>
                          );
                        case "filter":
                          return (
                            <StyledTableCell align="left" key={header.key}>
                              {header?.array.filter((item) => item.value === row?.[header.key])[0]?.label}
                            </StyledTableCell>
                          );
                        default:
                          return (
                            <StyledTableCell align="left" key={header.key}>
                              {row?.[header.key]}
                            </StyledTableCell>
                          );
                      }
                    })}
                    {actions.length && (
                      <StyledTableCell align="left">
                        <div className={Styles.actions}>
                          {actions.map((action) => (
                            <div key={action.type}
                              className={Styles.subActions}
                              aria-disabled="true"
                              onClick={userData?.roleData[`${permissionPath}_${action.role}`] ? () => handleIconClick(action, row) : () => { }}
                            >
                              {action.type === "edit" && (userData?.roleData[`${permissionPath}_${action.role}`] ? <EditIcon /> : <EditIconDisabled />)}
                              {action.type === "view" && (userData?.roleData[`${permissionPath}_${action.role}`] ? <ViewIcon /> : <ViewIconDisabled />)}
                              {action.type === "viewNews" && (userData?.roleData[`${permissionPath}_${action.role}`] ? <ViewIcon /> : <ViewIconDisabled />)}
                              {action.type === "delete" && (userData?.roleData[`${permissionPath}_${action.role}`] ? <DeleteIcon /> : <DeleteIconDisabled />)}
                              {action.type === "playPause" && (userData?.roleData[`${permissionPath}_${action.role}`] ? (row?.status ? <PauseIcon /> : <PlayIcon />) : <PauseIconDisabled />)}
                            </div>
                          ))}
                        </div>
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                ))
              }
            </TableBody>
          </Table> :
            <Skeleton height={600} />}
        </TableContainer>
        {metaData && <Pagination page={currentPage} data={metaData} onPaginationChange={onPaginationChange} />}
      </div>

      <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose}>
        <DialogContent
          sx={{
            pb: theme => `${theme.spacing(6)} !important`,
            px: theme => [`${theme.spacing(5)} !important`, `${theme.spacing(15)} !important`],
            pt: theme => [`${theme.spacing(8)} !important`, `${theme.spacing(12.5)} !important`]
          }}
        >
          <Box
            sx={{
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              '& svg': { mb: 6, color: 'warning.main' }
            }}
          >
            <Typography>Are you sure you want to delete it?</Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            px: theme => [`${theme.spacing(5)} !important`, `${theme.spacing(15)} !important`],
            pb: theme => [`${theme.spacing(8)} !important`, `${theme.spacing(12.5)} !important`]
          }}
        >
          <Button variant='contained' sx={{ mr: 2 }} onClick={handleConfirmation}>
            Yes
          </Button>
          <Button variant='tonal' color='secondary' onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default TableComponent;
