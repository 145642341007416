import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, IconButton, InputAdornment, Link, Typography } from '@mui/material';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import CustomTextField from '../components/CustomTextField';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../features/slice/authSlice';
import { loginUser } from '../features/api/authApi';

const RightWrapper = styled(Box)(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
        maxWidth: 450
    },
    [theme.breakpoints.up('lg')]: {
        maxWidth: 600
    },
    [theme.breakpoints.up('xl')]: {
        maxWidth: 750
    }
}))

const LinkStyled = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: `${theme.palette.primary.main} !important`
}))

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
    '& .MuiFormControlLabel-label': {
        color: theme.palette.text.secondary
    }
}))

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    // const [rememberMe, setRememberMe] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const data = useSelector((state) => state?.auth);

    useEffect(() => {
        if (data?.isAuthenticated) {
            navigate('/');
        }
    }, [data, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        const userData = await loginUser({ email, password });
        if (userData?.status) {
            const { user, token } = userData?.data;
            localStorage.setItem('jwtToken', token);
            dispatch(setUser(user));
            navigate('/');
        } else {
            if (userData?.message.includes('email')) {
                setErrors({ email: { message: userData?.message } });
            } else if (userData?.message.includes('password')) {
                setErrors({ password: { message: userData?.message } });
            } else {
                setErrors({ others: { message: userData?.message } });
            }
        }
    }

    return (
        <Box className='content-right' sx={{ margin: 'auto', backgroundColor: 'background.paper' }}>
            <RightWrapper>
                <Box
                    sx={{
                        p: [6, 12],
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Box sx={{ width: '100%', maxWidth: 400 }}>
                        <Box sx={{ my: 6 }}>
                            <Typography variant='h3' sx={{ mb: 1.5 }}>
                                {`Welcome to NVTV Admin! 👋🏻`}
                            </Typography>
                        </Box>
                        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
                            <Box sx={{ mb: 4 }}>
                                <CustomTextField
                                    fullWidth
                                    autoFocus
                                    label='Email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder='admin@nvtv.com'
                                    error={Boolean(errors.email)}
                                    {...(errors.email && { helperText: errors?.email?.message })}
                                />
                            </Box>
                            <Box sx={{ mb: 1.5 }}>
                                <CustomTextField
                                    fullWidth
                                    value={password}
                                    label='Password'
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder='password'
                                    id='auth-login-v2-password'
                                    error={Boolean(errors.password)}
                                    {...(errors.password && { helperText: errors?.password?.message })}
                                    type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    edge='end'
                                                    onMouseDown={e => e.preventDefault()}
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>
                            <Box sx={{ mb: 1.75 }}>
                                {
                                    errors.others && <p style={{ color: '#d32f2f', fontSize: '13px', fontStyle: 'normal', fontWeight: '400', lineHeight: '15px', textAlign: 'left' }}>
                                        {errors?.others?.message}
                                    </p>
                                }
                            </Box>
                            {/* <Box
                                sx={{
                                    mb: 1.75,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <FormControlLabel
                                    label='Remember Me'
                                    control={<Checkbox checked={rememberMe} onChange={e => setRememberMe(e.target.checked)} />}
                                />
                                <Typography component={LinkStyled} href='/forgot-password'>
                                    Forgot Password?
                                </Typography>
                            </Box> */}
                            <Button fullWidth type='submit' variant='contained' sx={{ mb: 4 }}>
                                Login
                            </Button>
                        </form>
                    </Box>
                </Box>
            </RightWrapper>
        </Box>
    )
}

export default Login;
