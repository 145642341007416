import React from "react";
import SelectItem from "../Select";
import Styles from "./TableTopbar.module.scss";
import Search from "../Search";

const pageCounts = [
  {label: '5', value: '5'},
  {label: '10', value: '10'},
  {label: '20', value: '20'},
  {label: '25', value: '25'},
]

function TableTopbar({
  rowsPerPage,
  handleChangeRowsPerPage,
  searchTerm,
  setSearchTerm,
  onSearch,
}) {
  return (
    <div className={Styles.tableTopbar}>
      <div className={Styles.pageSelector}>
        <div className={Styles.label}>Show</div>
        <SelectItem
          value={rowsPerPage}
          selector='value'
          onChange={handleChangeRowsPerPage}
          items={pageCounts}
          sx={{ width: '86px' }}
        />
      </div>
      <Search searchTerm={searchTerm} onSearch={onSearch} setSearchTerm={setSearchTerm} />
    </div>
  );
}

export default TableTopbar;
