import Dashboard from "../pages";
import Navigation from "../pages/navigation";
import CreateEditNavigation from "../pages/navigation/craeteEdit";
import Pages from "../pages/pages";
import CreateEditPage from "../pages/pages/createEdit";
import AdNews from "../pages/news/adNews";
import News from "../pages/news";
import ScheduledPost from "../pages/scheduledPost";
import Drafts from "../pages/drafts";
import BreakingNews from "../pages/news/breakingNews";
import TopNews from "../pages/news/topNews";
import Youtube from "../pages/youtube";
import CreateEditYoutube from "../pages/youtube/craeteEdit";
import Categories from "../pages/categories";
import CreateEdiCategory from "../pages/categories/createEdit";
import Comments from "../pages/comments";
import Ads from "../pages/ads";
import CreateEditAd from "../pages/ads/craeteEdit";
import Career from "../pages/career";
import CreateEditCareer from "../pages/career/createEdit";
import Users from "../pages/users";
import CreateEditUser from "../pages/users/craeteEdit";
import Games from "../pages/games";
import CreateEditGame from "../pages/games/craeteEdit";
import Articles from "../pages/articles";
import CreateEditArticles from "../pages/articles/createEdit";
import Roles from "../pages/roles";
import CreateEditRole from "../pages/roles/craeteEdit";
import Profile from "../pages/profile";
import Contacts from "../pages/messages/contacts";
import Grievances from "../pages/messages/grievances";
import MessageView from "../pages/messages/view";
import Settings from "../pages/Settings";

const ROUTER = [
  {
    url: "/",
    component: Dashboard,
  },
  {
    url: "/navigation",
    component: Navigation,
  },
  {
    url: "/navigation/create",
    component: CreateEditNavigation,
  },
  {
    url: "/navigation/edit/:id",
    component: CreateEditNavigation,
  },
  {
    url: "/pages",
    component: Pages,
  },
  {
    url: "/pages/create",
    component: CreateEditPage,
  },
  {
    url: "/pages/edit/:id",
    component: CreateEditPage,
  },
  {
    url: "/addNews",
    component: AdNews,
  },
  {
    url: "/addNews/:id",
    component: AdNews,
  },
  {
    url: "/news",
    component: News,
  },
  {
    url: "/schedulePost",
    component: ScheduledPost,
  },
  {
    url: "/drafts",
    component: Drafts,
  },
  {
    url: "/breaking-news",
    component: BreakingNews,
  },
  {
    url: "/top-news",
    component: TopNews,
  },
  {
    url: "/youtube",
    component: Youtube,
  },
  {
    url: "/youtube/create",
    component: CreateEditYoutube,
  },
  {
    url: "/youtube/edit/:id",
    component: CreateEditYoutube,
  },
  {
    url: "/categories",
    component: Categories,
  },
  {
    url: "/categories/create",
    component: CreateEdiCategory,
  },
  {
    url: "/categories/edit/:id",
    component: CreateEdiCategory,
  },
  {
    url: "/comments",
    component: Comments,
  },
  {
    url: "/ads",
    component: Ads,
  },
  {
    url: "/ads/create",
    component: CreateEditAd,
  },
  {
    url: "/ads/edit/:id",
    component: CreateEditAd,
  },
  {
    url: "/career",
    component: Career,
  },
  {
    url: "/career/create",
    component: CreateEditCareer,
  },
  {
    url: "/career/edit/:id",
    component: CreateEditCareer,
  },
  {
    url: "/users",
    component: Users,
  },
  {
    url: "/users/create",
    component: CreateEditUser,
  },
  {
    url: "/users/edit/:id",
    component: CreateEditUser,
  },
  {
    url: "/games",
    component: Games,
  },
  {
    url: "/games/create",
    component: CreateEditGame,
  },
  {
    url: "/games/edit/:id",
    component: CreateEditGame,
  },
  {
    url: "/articles",
    component: Articles,
  },
  {
    url: "/articles/create",
    component: CreateEditArticles,
  },
  {
    url: "/articles/edit/:id",
    component: CreateEditArticles,
  },
  {
    url: "/roles",
    component: Roles,
  },
  {
    url: "/roles/create",
    component: CreateEditRole,
  },
  {
    url: "/roles/edit/:id",
    component: CreateEditRole,
  },
  {
    url: "/profile",
    component: Profile,
  },
  {
    url: "/contacts",
    component: Contacts,
  },
  {
    url: "/grievances",
    component: Grievances,
  },
  {
    url: "/messages/view/:id",
    component: MessageView,
  },
  {
    url: "/settings",
    component: Settings,
  },
];

export { ROUTER };
