import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setAuthHeader } from './apiHelpers';
import { liveAppUrl, demoAppUrl, localAppUrl } from '../../config';

const currentURL = window.location.href;
const appURL = currentURL.includes('localhost') ? localAppUrl : currentURL.includes('glidix') ? demoAppUrl : liveAppUrl;

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: appURL,
    prepareHeaders: (headers) => {
      setAuthHeader(headers);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchSettings: builder.query({
      query: () => '/settings/list',
    }),
    updateSettings: builder.mutation({
      query: (credentials) => ({
        url: '/settings/update',
        method: 'PUT',
        body: credentials,
      }),
    }),
  }),
});

export const {
  useFetchSettingsQuery,
  useUpdateSettingsMutation,
} = settingsApi;
