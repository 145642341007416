import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setAuthHeader } from './apiHelpers';
import { liveAppUrl, demoAppUrl, localAppUrl } from '../../config';

const currentURL = window.location.href;
const appURL = currentURL.includes('localhost') ? localAppUrl : currentURL.includes('glidix') ? demoAppUrl : liveAppUrl;

export const youtubeApi = createApi({
  reducerPath: 'youtubeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: appURL,
    prepareHeaders: (headers) => {
      setAuthHeader(headers);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchYoutube: builder.query({
      query: (params) => {
        if (params?.search) {
          return `/youtube/list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}&search=${params.search}`;
        } else {
          return `/youtube/list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}`;
        }
      }
    }),
    fetchYoutubeDetail: builder.query({
      query: (id) => `/youtube/detail/${id}`,
    }),
    createYoutube: builder.mutation({
      query: (credentials) => ({
        url: '/youtube/store',
        method: 'POST',
        body: credentials,
      }),
    }),
    updateYoutube: builder.mutation({
      query: (credentials) => ({
        url: '/youtube/update',
        method: 'POST',
        body: credentials,
      }),
    }),
    deleteYoutube: builder.mutation({
      query: (id) => ({
        url: `/youtube/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchYoutubeQuery,
  useFetchYoutubeDetailQuery,
  useCreateYoutubeMutation,
  useUpdateYoutubeMutation,
  useDeleteYoutubeMutation
} = youtubeApi;
