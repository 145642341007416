import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EditHeader from "../../components/EditHeader";
import CustomTextField from "../../components/CustomTextField";
import SelectItem from '../../components/Select';
import TextArea from '../../components/TextArea';
import EditorComponent from '../../components/Editor';
import RadioButton from "../../components/RadioButton";
import { LOCAITIONS, VIEW_STATUS } from "../../utils";
import { useFetchCategoriesQuery, useCreateCategoryMutation, useFetchCategoryDetailQuery, useUpdateCategoryMutation } from '../../features/api/categoryApi';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import TagBox from '../../components/Tag';
import { isEmpty, validateData } from '../../helper';
import { useSelector } from 'react-redux';

function CreateEditPage() {
    const navigate = useNavigate();
    let { id: documentId } = useParams();
    const [tagValue, setTagValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        home_visibility: 'show',
        menu_visibility: 'top',
        color: '#ff0000',
        tags: [],
        is_news: false,
    });
    const userData = useSelector(state => state?.auth?.user);

    const { data: categoryDetailData, refetch } = useFetchCategoryDetailQuery(documentId);

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        if (!documentId && !userData?.roleData['categories_create']) {
            navigate('/');
        }
        if (documentId && !userData?.roleData['categories_edit']) {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData?.roleData]);

    useEffect(() => {
        setData({ ...data, ...categoryDetailData?.data });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryDetailData]);

    const { data: categoryData } = useFetchCategoriesQuery();

    const [createCategory] = useCreateCategoryMutation();
    const [updateCategory] = useUpdateCategoryMutation();

    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    const onColorChange = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const handleHtmlChange = (newHtmlContent) => {
        setData({ ...data, content: `${newHtmlContent}` });
    };

    const onBackClick = () => {
        navigate(-1);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (!data.tags.includes(tagValue)) {
                setData({ ...data, tags: [...data.tags, tagValue] });
            }
            setTagValue('');
        }
    };

    const handleAddTag = () => {
        if (!data.tags.includes(tagValue)) {
            setData({ ...data, tags: [...data.tags, tagValue] });
        }
        setTagValue('');
    };

    const handleTagCancel = (tagToRemove) => {
        setData({ ...data, tags: data?.tags.filter((item) => item !== tagToRemove) });
    };

    const onCheckBoxChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.checked });
    };

    const saveChanges = async () => {
        setLoading(true);
        const errors = validateData(data, ['name', 'color', 'menu_order']);
        if (!isEmpty(errors)) {
            setLoading(false);
            toast.error(errors, {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
            });
            return true;
        }

        let response = {};
        if (documentId) {
            response = await updateCategory(data);
        } else {
            response = await createCategory(data);
        }
        if (response?.data?.status) {
            setLoading(false);
            toast.success(response?.data?.message, {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 1000,
            });
            setTimeout(() => {
                navigate('/categories')
            }, 2000);
        } else {
            setLoading(false);
            toast.error(response?.data?.message, {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
            });
        }
    };

    const mainCategories = documentId ? categoryData?.data?.filter((item) => item._id !== documentId) : categoryData?.data;

    return (
        <div className="container">
            <EditHeader title="Add Category" navigateUrl="/categories" />
            <div className="detailSection">
                <div className="columnTwo">
                    <CustomTextField fullWidth name='name' required value={data?.name} onChange={onChange} label='Category Name' placeholder='Category Name' />
                    <SelectItem
                        title="Parent Category"
                        value={data?.parent_id ? mainCategories?.filter((item) => item._id === data?.parent_id)[0]?.name : ''}
                        selector='_id'
                        name="parent_id"
                        items={mainCategories}
                        placeHolder="Parent Category"
                        onChange={onChange}
                    />
                </div>

                <div className='columnOne'>
                    <CustomTextField fullWidth name="slug" disabled={documentId ? true : false} value={data?.slug} onChange={onChange} label='Slug (If you leave it blank, it will be generated automatically.)' placeholder='Slug' />
                </div>

                <div className="columnOne">
                    <TextArea rowCount={4} name="meta_desc" value={data?.meta_desc} onChange={onChange} label="Description (Meta Tag)" placeholder="Summary & Description (Meta Tag)" />
                </div>

                <div className='columnOne'>
                    <CustomTextField fullWidth name="keywords" value={data?.keywords} onChange={onChange} label='Keywords (Meta Tag)' placeholder='Keywords (Meta Tag)' />
                </div>

                <div className='columnOne'>
                    <div className='tagParent'>
                        <CustomTextField
                            fullWidth
                            name='tags'
                            value={tagValue}
                            onChange={(e) => setTagValue(e.target.value)}
                            onKeyPress={handleKeyPress}
                            label='Tags'
                            placeholder='Add a tag'
                            helperText='(Type tag and hit enter)'
                        />
                        <Button
                            className='addTagMobile'
                            onClick={handleAddTag}
                            size='small'
                            sx={{
                                backgroundColor: '#7367F0',
                                '&:hover': {
                                    backgroundColor: '#6056CD',
                                },
                                color: '#ffffff',
                                padding: '8px 20px',
                                height: '35px',
                                marginLeft: '10px',
                                top: '23px',
                            }}
                        >Add Tag</Button>
                    </div>
                    {data?.tags && <div className="tagOuter">
                        {data?.tags.map((tag, index) => (
                            <TagBox key={index} tag={tag} handleTagCancel={handleTagCancel} />
                        ))}
                    </div>}
                </div>

                <div className="columnTwo">
                    <CustomTextField fullWidth name="color" required value={data?.color} onChange={onColorChange} label='Color' color={true} placeholder='Color' />
                    <CustomTextField fullWidth name="menu_order" type="number" required value={data?.menu_order} onChange={onChange} label='Menu Order' placeholder='Menu Order' />
                </div>

                <div className='columnOne'>
                    <EditorComponent name="content" value={data?.content} onChange={handleHtmlChange} title="Content" />
                </div>

                <div className='columnOne'>
                    <RadioButton title="Show on Menu" value={data?.menu_visibility || 'top'} onChange={onChange} name="menu_visibility" items={LOCAITIONS} />
                </div>

                <div className='columnOne checkboxAlign'>
                    <FormControlLabel control={<Checkbox
                        name="is_news"
                        sx={{
                            color: 'rgba(47, 43, 61, 0.20);',
                            fontFamily: 'Public Sans',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '15px'
                        }}
                        checked={data?.is_news}
                        onChange={onCheckBoxChange}
                    />}
                        label="News Category"
                    />
                </div>

                <div className='columnOne'>
                    <RadioButton title="Show on Home Page" value={data?.home_visibility || 'show'} onChange={onChange} name="home_visibility" items={VIEW_STATUS} />
                </div>

                <div className="newsSubmit">
                    <Button
                        sx={{
                            backgroundColor: '#AAAAAA', '&:hover': {
                                backgroundColor: '#767676',
                            }, color: '#ffffff', padding: '8px 20px', marginRight: '15px'
                        }}
                        startIcon={<KeyboardBackspaceIcon />}
                        onClick={onBackClick}
                        disabled={loading}
                    >
                        Back
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: '#7367F0', '&:hover': {
                                backgroundColor: '#6056CD',
                            }, color: '#ffffff', padding: '8px 20px'
                        }}
                        startIcon={documentId ? <SaveIcon /> : <AddIcon />}
                        onClick={saveChanges}
                        disabled={loading}
                    >
                        {`${documentId ? 'Update' : 'Add'} Category`}
                    </Button>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default CreateEditPage; 