// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TagBox_tagItem__S711q {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px 10px;
  position: relative;
  margin-right: 15px;
}
.TagBox_tagItem__S711q span {
  position: absolute;
  top: -12px;
  right: -12px;
}`, "",{"version":3,"sources":["webpack://./src/components/Tag/TagBox.module.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;AACJ;AACI;EACI,kBAAA;EACA,UAAA;EACA,YAAA;AACR","sourcesContent":[".tagItem {\n    border: 1px solid #ddd;\n    border-radius: 5px;\n    padding: 5px 10px;\n    position: relative;\n    margin-right: 15px;\n\n    span {\n        position: absolute;\n        top: -12px;\n        right: -12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tagItem": `TagBox_tagItem__S711q`
};
export default ___CSS_LOADER_EXPORT___;
