export const appendToFormData = (datas) => {
    const formData = new FormData();
    Object.keys(datas).forEach((data) => {
        if (datas[data] instanceof Array && datas[data][0] instanceof File) {
            for (let i = 0; i < datas[data].length; i += 1) {
                formData.append(`${data}[]`, datas[data][i]);
            }
        } else if (datas[data] instanceof File || typeof datas[data] !== "object") {
            formData.append(data, datas[data]);
        } else if (datas[data] instanceof Date && datas[data].getTime()) {
            formData.append(data, datas[data]);
        } else {
            formData.append(data, JSON.stringify(datas[data]));
        }
    });
    return formData;
};

export const isEmpty = (value) => {
    return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0) ||
        (typeof Array.isArray(value) && value.length === 0)
    );
}

const validateSlug = (slug) => {
    const slugPattern = /^[a-z0-9-]+$/;
    return slugPattern.test(slug);
};

const validateColor = (color) => {
    const colorPattern = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    return colorPattern.test(color);
};

const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
};

const validateMobile = (mobile) => {
    const mobilePattern = /^\d{10}$/;
    return mobilePattern.test(mobile);
};

const validateUrl = (url) => {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlPattern.test(url);

};
const validateContent = (content) => {
    const sanitizedContent = content.replace(/<[^>]*>/g, "");
    return sanitizedContent.length >= 10;
};

export const validateData = (data, fields) => {
    for (const field of fields) {
        switch (field) {
            case 'name':
            case 'title':
            case 'link':
            case 'slug':
                if (!data[field]) {
                    return `The ${field} field can't be empty`;
                }
                break;
            case 'email':
                if (data[field]) {
                    const isValid = validateEmail(data[field]);
                    if (!isValid) {
                        return `Please enter a valid email address`;
                    }
                } else {
                    return `The ${field} field can't be empty`;
                }
                break;
            case 'slug':
                const isValid = validateSlug(data[field]);
                if (data[field] && !isValid) {
                    return `Invalid slug. Slug should only contain lowercase letters, numbers, and hyphens`;
                }
                break;
            case 'mobile':
                if (data[field]) {
                    const isValid = validateMobile(data[field]);
                    if (!isValid) {
                        return `Please enter a valid mobile number`;
                    }
                } else {
                    return `The ${field} field can't be empty`;
                }
                break;
            case 'role':
                if (!data[field]) {
                    return `Please select an option from ${field}`;
                }
                break;
            case 'description':
                if (!data[field]) {
                    return `The ${field} field can't be empty`;
                }
                break;
            case 'author_id':
            case 'parent_id':
            case 'parent_link':
            case 'category_id':
                if (!data[field]) {
                    return `Please select an option from ${field.replace("_id", "")}`;
                }
                break;
            case 'color':
                if (data[field]) {
                    const isValid = validateColor(data[field]);
                    if (!isValid) {
                        return `Please enter a valid color code`;
                    }
                } else {
                    return `The ${field} field can't be empty`;
                }
                break;
            case 'menu_order':
                if (!data[field]) {
                    return `The ${field.replace(/_/g, ' ')} field can't be empty`;
                }
                break;
            case 'content':
                if (data[field]) {
                    const isValid = validateContent(data[field]);
                    if (!isValid) {
                        return `The content must be atleast 10 characters`;
                    }
                } else {
                    return `The content field can't be empty`;
                }
                break;
            case 'url':
                if (data[field]) {
                    const isValid = validateUrl(data[field]);
                    if (!isValid) {
                        return `Please enter a valid url`;
                    }
                } else {
                    return `The ${field} field can't be empty`;
                }
                break;
            case 'password':
                if (data[field]) {
                    if (data[field].length < 6) {
                        return `The ${field} field must be atleast 6 characters`;
                    }
                } else {
                    return `The ${field} field can't be empty`;
                }
                break;
            default:
                if (!data[field]) {
                    return `The ${field.replace(/_/g, ' ')} field can't be empty`;
                }
                break;
        }
    };
    return null;
}