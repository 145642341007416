import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setAuthHeader } from './apiHelpers';
import { liveAppUrl, demoAppUrl, localAppUrl } from '../../config';

const currentURL = window.location.href;
const appURL = currentURL.includes('localhost') ? localAppUrl : currentURL.includes('glidix') ? demoAppUrl : liveAppUrl;

export const roleApi = createApi({
  reducerPath: 'roleApi',
  baseQuery: fetchBaseQuery({
    baseUrl: appURL,
    prepareHeaders: (headers) => {
      setAuthHeader(headers);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchRole: builder.query({
      query: (params) => {
        if (params?.search) {
          return `/role/list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}&search=${params.search}`;
        } else if (params?.page) {
          return `/role/list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}`;
        } else {
          return '/role/list';
        }
      }
    }),
    fetchRoleDetail: builder.query({
      query: (id) => `/role/detail/${id}`,
    }),
    createRole: builder.mutation({
      query: (credentials) => ({
        url: '/role/store',
        method: 'POST',
        body: credentials,
      }),
    }),
    updateRole: builder.mutation({
      query: (credentials) => ({
        url: '/role/update',
        method: 'PUT',
        body: credentials,
      }),
    }),
    deleteRole: builder.mutation({
      query: (id) => ({
        url: `/role/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchRoleQuery,
  useFetchRoleDetailQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation
} = roleApi;
