import React from "react";

export default function deleteicon() {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 3.8H2.44444M2.44444 3.8H14M2.44444 3.8V13.6C2.44444 13.9713 2.59663 14.3274 2.86751 14.5899C3.1384 14.8525 3.5058 15 3.88889 15H11.1111C11.4942 15 11.8616 14.8525 12.1325 14.5899C12.4034 14.3274 12.5556 13.9713 12.5556 13.6V3.8M4.61111 3.8V2.4C4.61111 2.0287 4.76329 1.6726 5.03418 1.41005C5.30507 1.1475 5.67246 1 6.05556 1H8.94444C9.32753 1 9.69493 1.1475 9.96582 1.41005C10.2367 1.6726 10.3889 2.0287 10.3889 2.4V3.8M6.05556 7.3V11.5M8.94444 7.3V11.5"
        stroke="#E04C5E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
