import React from "react";

export default function viewicon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" stroke="#7367f0" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                <circle cx="12" cy="12" r="2"></circle>
                <path d="M22 12c-2.667 4.667-6 7-10 7s-7.333-2.333-10-7c2.667-4.667 6-7 10-7s7.333 2.333 10 7"></path>
            </g>
        </svg>
    );
}
