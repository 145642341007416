// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavBar_logoSection__33wfk {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
  background: #ffffff;
  height: 70px;
  background: #ffffff;
}
.NavBar_logoSection__33wfk .NavBar_logo1__hVoOs, .NavBar_logoSection__33wfk .NavBar_logo2__puBu2 {
  padding-left: 10px;
}

.NavBar_nav-list__9ywe6 .NavBar_MuiListItemIcon-root__Fmbao {
  min-width: 37px;
}

.NavBar_childNav__hX9S2 {
  padding: 5px 25px !important;
  color: #4b4b4b !important;
}`, "",{"version":3,"sources":["webpack://./src/components/NavBar/NavBar.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,MAAA;EACA,UAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;AACJ;AAAI;EACI,kBAAA;AAER;;AAEI;EACI,eAAA;AACR;;AAEA;EACI,4BAAA;EACA,yBAAA;AACJ","sourcesContent":[".logoSection {\n    display: flex;\n    align-items: center;\n    position: sticky;\n    top: 0;\n    z-index: 2;\n    background: #ffffff;\n    height: 70px;\n    background: #ffffff;\n    .logo1, .logo2 {\n        padding-left: 10px;\n    }\n}\n.nav-list {\n    .MuiListItemIcon-root {\n        min-width: 37px;\n    }\n}\n.childNav {\n    padding: 5px 25px !important;\n    color: #4b4b4b !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoSection": `NavBar_logoSection__33wfk`,
	"logo1": `NavBar_logo1__hVoOs`,
	"logo2": `NavBar_logo2__puBu2`,
	"nav-list": `NavBar_nav-list__9ywe6`,
	"MuiListItemIcon-root": `NavBar_MuiListItemIcon-root__Fmbao`,
	"childNav": `NavBar_childNav__hX9S2`
};
export default ___CSS_LOADER_EXPORT___;
