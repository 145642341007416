import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setAuthHeader } from './apiHelpers';
import { liveAppUrl, demoAppUrl, localAppUrl } from '../../config';

const currentURL = window.location.href;
const appURL = currentURL.includes('localhost') ? localAppUrl : currentURL.includes('glidix') ? demoAppUrl : liveAppUrl;

export const newsApi = createApi({
  reducerPath: 'newsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: appURL,
    prepareHeaders: (headers) => {
      setAuthHeader(headers);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchNews: builder.query({
      query: (params) => {
        if (params?.filter) {
          return `/news/list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}&filter=${params?.filter}`;
        } else if (params?.search) {
          return `/news/list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}&search=${params?.search}`;
        } else {
          return `/news/list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}`;
        }
      }
    }),
    fetchDrafts: builder.query({
      query: (params) => {
        if (params?.filter) {
          return `/news/drafts?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}&filter=${params?.filter}`;
        } else if (params?.search) {
          return `/news/drafts?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}&search=${params.search}`;
        } else {
          return `/news/drafts?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}`;
        }
      }
    }),
    fetchScheduledPosts: builder.query({
      query: (params) => {
        if (params?.filter) {
          return `/news/schedule-posts?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}&filter=${params?.filter}`;
        } else if (params?.search) {
          return `/news/schedule-posts?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}&search=${params.search}`;
        } else {
          return `/news/schedule-posts?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}`;
        }
      }
    }),
    fetchBreakingNews: builder.query({
      query: (params) => {
        if (params?.filter) {
          return `/news/breaking-news-list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}&filter=${params?.filter}`;
        } else if (params?.search) {
          return `/news/breaking-news-list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}&search=${params.search}`;
        } else {
          return `/news/breaking-news-list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}`;
        }
      }
    }),
    fetchTopNews: builder.query({
      query: (params) => {
        if (params?.filter) {
          return `/news/top-news-list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}&filter=${params?.filter}`;
        } else if (params?.search) {
          return `/news/top-news-list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}&search=${params.search}`;
        } else {
          return `/news/top-news-list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}`;
        }
      }
    }),
    fetchNewsCount: builder.query({
      query: (id) => `/news/count`,
    }),
    fetchNewsDetail: builder.query({
      query: (id) => `/news/detail/${id}`,
    }),
    createNews: builder.mutation({
      query: (credentials) => ({
        url: '/news/store',
        method: 'POST',
        body: credentials,
      }),
    }),
    updateNews: builder.mutation({
      query: (credentials) => ({
        url: '/news/update',
        method: 'POST',
        body: credentials,
      }),
    }),
    deleteNews: builder.mutation({
      query: (id) => ({
        url: `/news/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchNewsQuery,
  useFetchDraftsQuery,
  useFetchScheduledPostsQuery,
  useFetchBreakingNewsQuery,
  useFetchTopNewsQuery,
  useFetchNewsCountQuery,
  useFetchNewsDetailQuery,
  useCreateNewsMutation,
  useUpdateNewsMutation,
  useDeleteNewsMutation
} = newsApi;
