import React, { useEffect, useState } from "react";
import TableComponent from "../../components/Table";
import { useFetchNavigationsQuery, useDeleteNavigationMutation } from '../../features/api/navigationApi';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const headers = [
  { title: "#", key: "id", type: "id" },
  { title: "TITLE", key: "title", type: "text" },
  { title: "LINK", key: "link", type: "text" },
  { title: "PARENT", key: "title", type: "parent", parent: "parent" },
  { title: "MENU LIMIT", key: "menu_limit", type: "text" },
];

function Navigation() {
  const navigate = useNavigate();
  const [params, setParams] = useState({ page: 1, perPage: 10 });
  const { data, refetch } = useFetchNavigationsQuery(params);
  const userData = useSelector(state => state?.auth?.user);

  useEffect(() => {
    refetch();
  }, [refetch, params]);

  useEffect(() => {
    if (!userData?.roleData['navigation_viewAll']) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.roleData]);

  const [deleteNavigation] = useDeleteNavigationMutation();

  const handleEdit = (id) => {
    navigate(`/navigation/edit/${id}`);
  }

  const handleDelete = async (id) => {
    const response = await deleteNavigation(id);
    refetch();
    if (response?.data?.status) {
      toast.success(response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
    }
  }

  const onSearch = (value) => {
    setParams({ ...params, search: value, page: 1 })
  }

  const onPaginationCountChange = (value) => {
    setParams({ ...params, perPage: value })
  }

  const onPaginationChange = (value) => {
    setParams({ ...params, page: value })
  }

  return (
    <div className="container">
      <div className="table-listing">
        <TableComponent
          title="Navigation"
          defaultTitle={true}
          headers={headers}
          data={data?.data?.list}
          metaData={data?.data?.meta}
          rows={10}
          buttonContent="Add Navigation"
          view={false}
          add={true}
          buttonStatus={true}
          actions={[{ type: 'edit', role: 'edit', onClick: handleEdit }, { type: 'delete', role: 'delete', onClick: handleDelete }]}
          permissionPath="navigation"
          currentPage={params?.page}
          onPaginationChange={onPaginationChange}
          onSearch={onSearch}
          onPaginationCountChange={onPaginationCountChange}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default Navigation;
