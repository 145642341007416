import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setAuthHeader } from './apiHelpers';
import { liveAppUrl, demoAppUrl, localAppUrl } from '../../config';

const currentURL = window.location.href;
const appURL = currentURL.includes('localhost') ? localAppUrl : currentURL.includes('glidix') ? demoAppUrl : liveAppUrl;

export const advertisementApi = createApi({
  reducerPath: 'advertisementApi',
  baseQuery: fetchBaseQuery({
    baseUrl: appURL,
    prepareHeaders: (headers) => {
      setAuthHeader(headers);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchAdvertisement: builder.query({
      query: (params) => {
        if (params?.filter) {
          return `/advertisement/list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}&filter=${params?.filter}`;
        } else if (params?.search) {
          return `/advertisement/list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}&search=${params?.search}`;
        } else {
          return `/advertisement/list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}`;
        }
      }
    }),
    fetchAdvertisementDetail: builder.query({
      query: (id) => `/advertisement/detail/${id}`,
    }),
    createAdvertisement: builder.mutation({
      query: (credentials) => ({
        url: '/advertisement/store',
        method: 'POST',
        body: credentials,
      }),
    }),
    updateAdvertisement: builder.mutation({
      query: (credentials) => ({
        url: '/advertisement/update',
        method: 'POST',
        body: credentials,
      }),
    }),
    deleteAdvertisement: builder.mutation({
      query: (id) => ({
        url: `/advertisement/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchAdvertisementQuery,
  useFetchAdvertisementDetailQuery,
  useCreateAdvertisementMutation,
  useUpdateAdvertisementMutation,
  useDeleteAdvertisementMutation
} = advertisementApi;
