import axios from 'axios';
import { liveAppUrl, demoAppUrl, localAppUrl } from '../../config';
import { clearUser } from '../../features/slice/authSlice';
import store from '../../store';
const currentURL = window.location.href;
const appURL = currentURL.includes('localhost') ? localAppUrl : currentURL.includes('glidix') ? demoAppUrl : liveAppUrl;

const setAuthToken = (token) => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
};

const token = localStorage.jwtToken;
setAuthToken(token);


export const loginUser = async (credentials) => {
    try {
        const response = await axios.post(`${appURL}/auth/login`, credentials);
        setAuthToken(response?.data?.data?.token);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const logoutUser = async () => {
    try {
        const response = await axios.post(`${appURL}/auth/logout`);
        return response.data;
    } catch (error) {
        throw error;
    }

};

export const getCurrentUser = async (decoded) => {
    try {
        const response = await axios.get(`${appURL}/auth/user-profile`);
        return response.data;
    } catch (error) {
       if(error?.response?.status==401){
        store.dispatch(clearUser());
        return;
       }
        throw error;
    }
};