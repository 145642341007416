import React from "react";
import { FormControlLabel, Switch } from '@mui/material';

function CustomSwitch({ name, label, value, onChange }) {
  return (
    <FormControlLabel label={label} control={
      <Switch
        name={name}
        checked={value}
        onChange={onChange}
      />} />
  );
}

export default CustomSwitch;
