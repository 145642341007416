// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextField_textFieldOuter__6N83Z {
  position: relative;
  width: 100%;
}
.TextField_textFieldOuter__6N83Z .TextField_colorPickBox__KIRGi {
  height: 20px;
  width: 20px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #eaeaea;
}
.TextField_textFieldOuter__6N83Z .TextField_customSketchPicker__vQYmw {
  position: absolute;
  right: 0;
  z-index: 9;
}
@media screen and (max-width: 600px) {
  .TextField_textFieldOuter__6N83Z {
    padding-bottom: 30px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/CustomTextField/TextField.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;AACJ;AAAI;EACI,YAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,yBAAA;AAER;AAAI;EACI,kBAAA;EACA,QAAA;EACA,UAAA;AAER;AAAI;EAfJ;IAgBQ,oBAAA;EAGN;AACF","sourcesContent":[".textFieldOuter {\n    position: relative;\n    width: 100%;\n    .colorPickBox {\n        height: 20px;\n        width: 20px;\n        border-radius: 5px;\n        cursor: pointer;\n        border: 1px solid #eaeaea;\n    }\n    .customSketchPicker {\n        position: absolute;\n        right: 0;\n        z-index: 9;\n    }\n    @media screen and (max-width: 600px) {\n        padding-bottom: 30px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textFieldOuter": `TextField_textFieldOuter__6N83Z`,
	"colorPickBox": `TextField_colorPickBox__KIRGi`,
	"customSketchPicker": `TextField_customSketchPicker__vQYmw`
};
export default ___CSS_LOADER_EXPORT___;
