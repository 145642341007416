import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setAuthHeader } from './apiHelpers';
import { liveAppUrl, demoAppUrl, localAppUrl } from '../../config';

const currentURL = window.location.href;
const appURL = currentURL.includes('localhost') ? localAppUrl : currentURL.includes('glidix') ? demoAppUrl : liveAppUrl;

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: appURL,
    prepareHeaders: (headers) => {
      setAuthHeader(headers);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchUser: builder.query({
      query: (params) => {
        if (params?.search) {
          return `/user/list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}&search=${params.search}`;
        } else {
          return `/user/list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}`;
        }
      }
    }),
    fetchAdminUser: builder.query({
      query: () => '/user/admin-list',
    }),
    fetchUserDetail: builder.query({
      query: (id) => `/user/detail/${id}`,
    }),
    createUser: builder.mutation({
      query: (credentials) => ({
        url: '/user/store',
        method: 'POST',
        body: credentials,
      }),
    }),
    updateUser: builder.mutation({
      query: (credentials) => ({
        url: '/user/update',
        method: 'POST',
        body: credentials,
      }),
    }),
    updateUserStatus: builder.mutation({
      query: (credentials) => ({
        url: '/user/update-status',
        method: 'PUT',
        body: credentials,
      }),
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/user/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchUserQuery,
  useFetchAdminUserQuery,
  useFetchUserDetailQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useUpdateUserStatusMutation,
  useDeleteUserMutation
} = userApi;
