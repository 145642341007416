import React, { useEffect, useState } from "react";
import EditHeader from "../../components/EditHeader";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from '@mui/icons-material/Save';
import FileUploader from "../../components/FileUploader";
import CustomTextField from "../../components/CustomTextField";
import Styles from "./youtube.module.scss";
import { useFetchYoutubeDetailQuery, useCreateYoutubeMutation, useUpdateYoutubeMutation } from '../../features/api/youtubeApi';
import { useNavigate, useParams } from 'react-router-dom';
import { appendToFormData, isEmpty, validateData } from '../../helper';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";

function CreateEditYoutube() {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  let { id: documentId } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const userData = useSelector(state => state?.auth?.user);

  const { data: youtubeData, refetch } = useFetchYoutubeDetailQuery(documentId);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (!documentId && !userData?.roleData['youtube_create']) {
      navigate('/');
    }
    if (documentId && !userData?.roleData['youtube_edit']) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.roleData]);

  useEffect(() => {
    setData({ ...data, ...youtubeData?.data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [youtubeData]);

  const [createYoutube] = useCreateYoutubeMutation();
  const [updateYoutube] = useUpdateYoutubeMutation();

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleUploadFiles = (name, files) => {
    setImage(files);
  }

  const saveChanges = async () => {
    setLoading(true);
    const errors = validateData(data, ['title', 'url']);
    if (!isEmpty(errors)) {
      setLoading(false);
      toast.error(errors, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
      return true;
    }

    let postData = data;
    let formData;
    formData = appendToFormData({
      ...postData,
      image: image || data?.image,
    });
    let response = {};
    if (documentId) {
      response = await updateYoutube(formData);
    } else {
      response = await createYoutube(formData);
    }
    if (response?.data?.status) {
      setLoading(false);
      toast.success(response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
      });
      setTimeout(() => {
        navigate('/youtube')
      }, 2000);
    } else {
      setLoading(false);
      toast.error(response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
    }
  };

  return (
    <div className="container">
      <EditHeader title="Youtube" navigateUrl="/youtube" />
      <div className={Styles.YoutubeDetails}>
        <div className={Styles.ImageUpload}>
          <div className={Styles.YoutubeData}>
            <CustomTextField fullWidth name="title" required value={data?.title} onChange={onChange} label='Title Here' placeholder='Title' />
            <CustomTextField fullWidth name="url" required value={data?.url} onChange={onChange} label='video Url' placeholder='Video Url' />
          </div>
          <FileUploader name="image" images={data?.image} setData={setData} data={data} fileCount={1} onFileDrop={handleUploadFiles} />
        </div>
      </div>
      <div className={Styles.saveYoutube}>
        <Button
          sx={{
            backgroundColor: '#7367F0', '&:hover': {
              backgroundColor: '#6056CD',
            }, color: '#ffffff', padding: '8px 20px',
            marginLeft: 'auto'
          }}
          startIcon={documentId ?<SaveIcon />:<AddIcon />}
          onClick={() => saveChanges()}
          disabled={loading}
        >
          {`${documentId ? 'Update' : 'Add'}`}
        </Button>
      </div>
      <ToastContainer />
    </div>
  );
}

export default CreateEditYoutube;
