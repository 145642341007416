const ROLES = [
    { value: 'navigation', label: 'Navigation' },
    { value: 'pages', label: 'Pages' },
    { value: 'news', label: 'News' },
    { value: 'schedulePost', label: 'Scheduled Post' },
    { value: 'youtube', label: 'Youtube' },
    { value: 'drafts', label: 'Drafts' },
    { value: 'categories', label: 'Categories' },
    { value: 'comments', label: 'Comments' },
    { value: 'ads', label: 'Ads' },
    { value: 'career', label: 'Career' },
    { value: 'users', label: 'Users' },
    { value: 'games', label: 'Games' },
    { value: 'articles', label: 'Articles' },
    { value: 'roles', label: 'Roles' },
    { value: 'messages', label: 'Messages' },
    { value: 'settings', label: 'Settings' },
];

const OWN_ROLES = ['news', 'schedulePost', 'drafts', 'career', 'articles'];

const CAPABILITIES = [
    { value: 'viewOwn', label: 'View Own' },
    { value: 'viewAll', label: 'View Global' },
    { value: 'create', label: 'Create' },
    { value: 'edit', label: 'Edit' },
    { value: 'delete', label: 'Delete' },
];

const VIEW_STATUS = [
    { value: 'show', label: 'Show' },
    { value: 'hide', label: 'Hide' },
];

const NEWS_LISTINGS = [
    { value: 'is_featured', label: 'Top News' },
    { value: 'is_breaking', label: 'Add to Breaking' },
    // { value: 'is_slider', label: 'Add to Slider' },
    // { value: 'is_recommended', label: 'Add to Recommended' },
    { value: 'for_registered_user', label: 'Show Only to Registered Users' },
    { value: 'is_daily_hunt', label: 'Add to Daily Hunt' },
    { value: 'is_trending', label: 'Trending News' },
];

const ARTICLE_LISTINGS = [
    { value: 'for_registered_user', label: 'Show Only to Registered Users' },
    { value: 'is_daily_hunt', label: 'Add to Daily Hunt' },
];

const LOCAITIONS = [
    { value: 'top', label: 'Top Menu' },
    { value: 'main', label: 'Main Menu' },
    { value: 'footer', label: 'Footer' },
    { value: 'none', label: `Don't Add to Menu` },
];

const AD_SPACES = [
    {value: 'header', label: 'Header'},
    {value: 'popUp', label: 'Pop Up'},
    {value: 'rightSection', label: 'Right Side Section'},
    {value: 'inBetweenNewsListing', label: 'In Between News Listing'},
    {value: 'inBetweenNewsDetail', label: 'In Between News Detail'},
    {value: 'afterNewsDetailImage', label: 'After News Detail Image'},
    {value: 'afterNewsDetailTags', label: 'After News Detail Tags'},
    {value: 'stickyBottom', label: 'Sticky Bottom'},
];

const GENDER = [
    {value: 'male', label: 'Male'},
    {value: 'female', label: 'Female'},
    {value: 'other', label: 'Other'},
];

const CATEGORY_TYPES = [
    { value: 'news', label: 'News' },
    { value: 'home', label: 'Home' },
    { value: 'career', label: 'Career' },
    { value: 'game', label: 'Game' },
    { value: 'youtube', label: 'Youtube' },
    { value: 'blog', label: 'Blog' },
    { value: 'section', label: 'Section' },
    { value: 'ad', label: 'Ads' },
];

export { ROLES, OWN_ROLES, CAPABILITIES, VIEW_STATUS, NEWS_LISTINGS, ARTICLE_LISTINGS, LOCAITIONS, AD_SPACES, GENDER, CATEGORY_TYPES };