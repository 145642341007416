// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ads_saveAds__SXa9a {
  display: flex;
  padding-bottom: 30px;
}

.ads_adDetails__Gqapa {
  display: flex;
}

.ads_uploadSection__XFI4v {
  padding: 15px 0;
}
.ads_uploadSection__XFI4v .ads_AdsData__b\\+OvN {
  padding-bottom: 15px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
.ads_uploadSection__XFI4v .ads_ImageUpload__viIJC {
  border: 1px solid #dbdade;
  padding: 20px;
  border-radius: 5px;
  min-height: 350px;
}
@media screen and (max-width: 600px) {
  .ads_uploadSection__XFI4v .ads_AdsData__b\\+OvN {
    display: block;
  }
}

.ads_userDetails__cxmab {
  padding: 15px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

@media screen and (max-width: 600px) {
  .ads_adDetails__Gqapa {
    display: block;
  }
  .ads_saveAds__SXa9a {
    justify-content: center;
  }
  .ads_saveAds__SXa9a button {
    margin-left: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/ads/ads.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,oBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,eAAA;AACJ;AACI;EACI,oBAAA;EACA,aAAA;EACA,qCAAA;EACA,SAAA;AACR;AAEI;EACI,yBAAA;EACA,aAAA;EACA,kBAAA;EACA,iBAAA;AAAR;AAGI;EACI;IACI,cAAA;EADV;AACF;;AAKA;EACI,eAAA;EACA,aAAA;EACA,qCAAA;EACA,SAAA;AAFJ;;AAKA;EACI;IACI,cAAA;EAFN;EAKE;IACI,uBAAA;EAHN;EAKM;IACI,cAAA;EAHV;AACF","sourcesContent":[".saveAds {\n    display: flex;\n    padding-bottom: 30px;\n}\n\n.adDetails {\n    display: flex;\n}\n\n.uploadSection {\n    padding: 15px 0;\n\n    .AdsData {\n        padding-bottom: 15px;\n        display: grid;\n        grid-template-columns: repeat(2, 1fr);\n        gap: 30px;\n    }\n\n    .ImageUpload {\n        border: 1px solid #dbdade;\n        padding: 20px;\n        border-radius: 5px;\n        min-height: 350px;\n    }\n\n    @media screen and (max-width: 600px) {\n        .AdsData {\n            display: block;\n        }\n    }\n}\n\n.userDetails {\n    padding: 15px 0;\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 15px;\n}\n\n@media screen and (max-width: 600px) {\n    .adDetails {\n        display: block;\n    }\n\n    .saveAds {\n        justify-content: center;\n\n        button {\n            margin-left: 0;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"saveAds": `ads_saveAds__SXa9a`,
	"adDetails": `ads_adDetails__Gqapa`,
	"uploadSection": `ads_uploadSection__XFI4v`,
	"AdsData": `ads_AdsData__b+OvN`,
	"ImageUpload": `ads_ImageUpload__viIJC`,
	"userDetails": `ads_userDetails__cxmab`
};
export default ___CSS_LOADER_EXPORT___;
