import React, { useEffect, useState } from "react";
import EditHeader from "../../components/EditHeader";
import CustomTextField from "../../components/CustomTextField";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from '@mui/icons-material/Save';
import RadioButton from "../../components/RadioButton";
import { CATEGORY_TYPES, VIEW_STATUS } from "../../utils";
import Styles from "./navigation.module.scss";
import SelectItem from "../../components/Select";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFetchNavigationsQuery, useFetchNavigationDetailQuery, useCreateNavigationMutation, useUpdateNavigationMutation } from '../../features/api/navigationApi';
import { useFetchCategoriesQuery } from '../../features/api/categoryApi';
import { ToastContainer, toast } from 'react-toastify';
import { isEmpty, validateData } from '../../helper';

function CreateEditNavigation() {
  const navigate = useNavigate();
  let { id: documentId } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    menu_visibility: 'show',
  });
  const userData = useSelector(state => state?.auth?.user);

  const { data: navigationDetailData, refetch } = useFetchNavigationDetailQuery(documentId);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (!documentId && !userData?.roleData['navigation_create']) {
      navigate('/');
    }
    if (documentId && !userData?.roleData['navigation_edit']) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.roleData]);

  useEffect(() => {
    setData({ ...data, ...navigationDetailData?.data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationDetailData]);

  const { data: categoryData } = useFetchCategoriesQuery();
  const { data: navigationData } = useFetchNavigationsQuery();

  const [createNavigation] = useCreateNavigationMutation();
  const [updateNavigation] = useUpdateNavigationMutation();

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleClearAll = (e) => {
    setData({ ...data, parent_link: null });
  }

  const saveChanges = async () => {
    setLoading(true);
    const errors = validateData(data, ['title', 'link', 'category_id', 'type']);
    if (!isEmpty(errors)) {
      setLoading(false);
      toast.error(errors, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
      return true;
    }

    let response = {};
    if (documentId) {
      response = await updateNavigation(data);
    } else {
      response = await createNavigation(data);
    }
    if (response?.data?.status) {
      setLoading(false);
      toast.success(response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
      });
      setTimeout(() => {
        navigate('/navigation')
      }, 2000);
    } else {
      setLoading(false);
      toast.error(response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
    }
  };

  return (
    <div className="container">
      <EditHeader title="Navigation" navigateUrl="/navigation" />
      <div className={Styles.navDetails}>
        <CustomTextField fullWidth name='title' required value={data?.title} onChange={onChange} label='Title' placeholder='Title' />
        <CustomTextField fullWidth name='link' required value={data?.link} onChange={onChange} label='Link' placeholder='Link' />
        <CustomTextField fullWidth name="menu_order" type="number" value={data?.menu_order} onChange={onChange} label='Menu Order' placeholder='1' />
        <div style={{ display: 'flex' }}>
          <div style={{ width: '80%' }}>
            <SelectItem
              title="Parent Link"
              value={data?.parent_link ? navigationData?.data?.filter((item) => item._id === data?.parent_link)[0]?.title : ''}
              selector='_id'
              name="parent_link"
              items={navigationData?.data}
              placeHolder="Parent Category"
              onChange={onChange}
              allowClear
            />
          </div>
          <div className="appendBtnDiv">
            <button className="btn btn-sm appendBtn" style={{
              padding: '.55rem',
              border: '1px solid #c5c4c9',
              borderRadius: '.3rem',
              marginTop:'1.4rem'
            }} onClick={handleClearAll}>x</button>
          </div>
        </div>


        <SelectItem
          title="Parent Category"
          required
          value={data?.category_id ? categoryData?.data?.filter((item) => item._id === data?.category_id)[0]?.name : ''}
          selector='_id'
          name="category_id"
          items={categoryData?.data}
          placeHolder="Parent Category"
          onChange={onChange}
        />


        <SelectItem
          title="Type"
          name="type"
          required
          value={data?.type ? CATEGORY_TYPES.filter((item) => item.value === data?.type)[0]?.label : ''}
          selector='value'
          items={CATEGORY_TYPES}
          placeHolder="Select Type"
          onChange={onChange}
        />
        <CustomTextField fullWidth name="menu_limit" type="number" value={data?.menu_limit} onChange={onChange} label='Menu Limit' placeholder='1' />
      </div>
      <div className={Styles.visibility}>
        <RadioButton title="Show on Menu" value={data?.menu_visibility} onChange={onChange} name="menu_visibility" items={VIEW_STATUS} />
      </div>
      <div className={Styles.buttons}>
        <Button
          sx={{
            backgroundColor: '#7367F0', '&:hover': {
              backgroundColor: '#6056CD',
            }, color: '#ffffff', padding: '8px 20px'
          }}
          startIcon={documentId ? <SaveIcon /> : <AddIcon />}
          onClick={saveChanges}
          disabled={loading}
        >
          {`${documentId ? 'Update' : 'Add'} Navigation`}
        </Button>
      </div>
      <ToastContainer />
    </div>
  );
}

export default CreateEditNavigation;
