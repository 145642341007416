import React from "react";

function TextArea({ rowCount, label, placeholder, defaultValue, name, onChange, value, disabled=false }) {
    return (
        <div className="customTextArea">
            <label>{label}</label>
            <textarea
                rows={rowCount}
                name={name}
                value={value}
                onChange={onChange}
                defaultValue={defaultValue}
                placeholder={placeholder}
                disabled={disabled}
            />
        </div>
    );
}
export default TextArea;