import React, { useEffect, useState } from 'react';
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from '@mui/icons-material/Save';
import DraftIcon from "../../assets/icons/drafticon";
import EditHeader from "../../components/EditHeader";
import CustomTextField from "../../components/CustomTextField";
import DateTimePickerItem from '../../components/DatePicker';
import SelectItem from '../../components/Select';
import TextArea from '../../components/TextArea';
import EditorComponent from '../../components/Editor';
import FileUploader from '../../components/FileUploader';
import RadioButton from "../../components/RadioButton";
import CustomSwitch from "../../components/CustomSwitch";
import { NEWS_LISTINGS, VIEW_STATUS } from "../../utils";
import CheckboxLabels from '../../components/CheckBox';
import { FormGroup } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useFetchCategoriesQuery } from '../../features/api/categoryApi';
import { useCreateNewsMutation, useFetchNewsDetailQuery, useUpdateNewsMutation } from '../../features/api/newsApi';
import { useFetchAdminUserQuery } from '../../features/api/userApi';
import { useNavigate, useParams } from 'react-router-dom';
import TagBox from '../../components/Tag';
import { appendToFormData, isEmpty, validateData } from '../../helper';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';

function AdNews() {
  const location = useLocation();
  const navigate = useNavigate();
  const { enableDateTimePicker } = location?.state || {};
  const [scheduledPost, setScheduledPost] = useState(enableDateTimePicker || false);
  const [tagValue, setTagValue] = useState('');
  const [mainImage, setMainImage] = useState(null);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [loading, setLoading] = useState(false);
  let { id: documentId } = useParams();
  const [data, setData] = useState({
    for_registered_user: false,
    is_breaking: false,
    is_daily_hunt: true,
    is_featured: false,
    is_premium: false,
    is_recommended: false,
    is_slider: false,
    is_trending: false,
    is_scheduled: false,
    visibility: "show",
    date_time: new Date().toISOString(),
    tags: []
  });
  const userData = useSelector(state => state?.auth?.user);

  const { data: newsDetailData, refetch } = useFetchNewsDetailQuery(documentId);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (!documentId && !userData?.roleData['news_create']) {
      navigate('/');
    }
    if (documentId && !userData?.roleData['news_edit']) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.roleData]);

  useEffect(() => {
    setData({ ...data, ...newsDetailData?.data });
    setSelectedCategory(newsDetailData?.data?.category_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsDetailData]);

  const { data: categoryData } = useFetchCategoriesQuery();
  const { data: adminUserData } = useFetchAdminUserQuery();

  const [createNews] = useCreateNewsMutation();
  const [updateNews] = useUpdateNewsMutation();

  const onChange = (e) => {
    if (e.target.name === "category_id") setSelectedCategory(e.target.value);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onDateChange = (name, date) => {
    setData({ ...data, [name]: date });
  };

  const onCheckBoxChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.checked });
  };

  const onToggleChange = (e) => {
    if (e.target.name === 'is_scheduled') setScheduledPost(!scheduledPost);
    setData({ ...data, [e.target.name]: e.target.checked, schedule_date_time: new Date().toISOString() });
  };

  const handleHtmlChange = (newHtmlContent) => {
    setData({ ...data, content: `${newHtmlContent}` });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (!data.tags.includes(tagValue)) {
        setData({ ...data, tags: [...data.tags, tagValue] });
      }
      setTagValue('');
    }
  };

  const handleAddTag = () => {
    if (!data.tags.includes(tagValue)) {
      setData({ ...data, tags: [...data.tags, tagValue] });
    }
    setTagValue('');
  };

  const handleTagCancel = (tagToRemove) => {
    setData({ ...data, tags: data?.tags.filter((item) => item !== tagToRemove) });
  };

  const handleUploadFiles = (name, files) => {
    if (name === "main_image") {
      setMainImage(files);
    } else {
      setAdditionalImages(files);
    }
  }

  const saveChanges = async (type = '') => {
    setLoading(true);
    let errors
    if (type === 'draft') {
      errors = validateData(data, ['title', 'slug']);
    } else {
      errors = validateData(data, ['title', 'slug', 'description', 'author_id', 'category_id', 'content']);
    }
    if (!isEmpty(errors)) {
      setLoading(false);
      toast.error(errors, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
      return true;
    }

    if (type === 'draft') {
      data.is_draft = true;
    } else {
      data.is_draft = false;
    }
    let postData = data;
    let formData;
    formData = appendToFormData({
      ...postData,
      main_image: mainImage || data?.main_image,
      additional_images: additionalImages?.length ? additionalImages : data?.additional_images
    });

    let response = {};
    if (documentId) {
      response = await updateNews(formData);
    } else {
      response = await createNews(formData);
    }
    if (response?.data?.status) {
      setLoading(false);
      toast.success(response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
      });
      setTimeout(() => {
        navigate(-1)
      }, 2000);
    } else {
      setLoading(false);
      toast.error(response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
    }
  };

  const mainCategories = categoryData?.data?.filter((item) => !item.parent_id && item.is_news);
  const subCategories = categoryData?.data?.filter((item) => item.parent_id && item.parent_id === selectedCategory);

  return (
    <div className="container">
      <EditHeader title="Add News" navigateUrl="/news" />
      <div className="detailSection">
        <div className='columnOne'>
          <CustomTextField fullWidth name='title' required value={data?.title} onChange={onChange} label='Title (Breaking news will only show a maximum of 65 characters!)' placeholder='Title' />
        </div>

        <div className='columnOne'>
          <CustomTextField fullWidth name='slug' disabled={documentId ? true : false} value={data?.slug} onChange={onChange} label='Slug (If you leave it blank, it will be generated automatically.)' placeholder='Slug' />
        </div>

        <div className='columnOne'>
          <CustomTextField fullWidth name='description' required value={data?.description} onChange={onChange} label='Short Description' placeholder='Short Description' />
        </div>

        <div className="columnTwo">
          <SelectItem
            title="Author Name"
            name="author_id"
            required
            value={data?.author_id ? adminUserData?.data?.filter((item) => item._id === data?.author_id)[0]?.name : ''}
            selector='_id'
            items={adminUserData?.data?.slice().sort((a, b) => a.name.localeCompare(b.name))}
            placeHolder="Select a Author"
            onChange={onChange}
          />
          <DateTimePickerItem name="date_time" value={data?.date_time} onChange={onDateChange} label="News Date and Time" dateTime={true} />
        </div>

        <div className="columnOne">
          <TextArea rowCount={4} name="meta_desc" value={data?.meta_desc} onChange={onChange} label="Meta Description" placeholder="Meta Description" defaultValue="" />
        </div>

        <div className="columnOne">
          <CustomTextField fullWidth name="keywords" value={data?.keywords} onChange={onChange} label='Meta Keywords' placeholder='Meta Keywords' />
        </div>

        <div className="columnTwo">
          <SelectItem
            title="Category"
            name="category_id"
            required
            value={data?.category_id ? mainCategories?.filter((item) => item._id === data?.category_id)[0]?.name : ''}
            selector='_id'
            items={mainCategories?.slice().sort((a, b) => a.name.localeCompare(b.name))}
            placeHolder="Select a category"
            onChange={onChange}
          />
          <SelectItem
            title="Sub Category"
            name="subcategory_id"
            value={data?.subcategory_id ? subCategories?.filter((item) => item._id === data?.subcategory_id)[0]?.name : ''}
            selector='_id'
            items={subCategories?.slice().sort((a, b) => a.name.localeCompare(b.name))}
            placeHolder="Select a category"
            onChange={onChange}
          />
        </div>

        <div className='columnOne'>
          <div className='tagParent'>
            <CustomTextField
              fullWidth
              name='tags'
              value={tagValue}
              onChange={(e) => setTagValue(e.target.value)}
              onKeyPress={handleKeyPress}
              label='Tags'
              placeholder='Add a tag'
              helperText='(Type tag and hit enter)'
            />
            <Button
              className='addTagMobile'
              onClick={handleAddTag}
              size='small'
              sx={{
                backgroundColor: '#7367F0',
                '&:hover': {
                  backgroundColor: '#6056CD',
                },
                color: '#ffffff',
                padding: '8px 20px',
                height: '35px',
                marginLeft: '10px',
                top: '23px',
              }}
            >Add Tag</Button>
          </div>
          {data?.tags && <div className="tagOuter">
            {data?.tags.map((tag, index) => (
              <TagBox key={index} tag={tag} handleTagCancel={handleTagCancel} />
            ))}
          </div>}
        </div>

        <div className='columnOne' style={{fontSize:'1.2rem',lineHeight:'1.2em' }}>
          <EditorComponent name="content" required value={data?.content} onChange={handleHtmlChange} title="Content *" />
        </div>

        <div className="columnTwo">
          <div className="imageUploadOuter">
            <p>Main Image * (For optimal display, please upload images with a width of 777 pixels and height of 429 pixels.)</p>
            <div className="imageUploadSection">
              <FileUploader name="main_image" images={data?.main_image} setData={setData} data={data} fileCount={1} onFileDrop={handleUploadFiles} />
              <div className="imageDescription">
                <CustomTextField fullWidth name='main_image_description' value={data?.main_image_description} onChange={onChange} label='Image Description' placeholder='Image Description' />
              </div>
            </div>
          </div>
          <div className="imageUploadOuter">
            <p className='additionalImage'>Additional Images</p>
            <div className="imageUploadSection">
              <FileUploader name="additional_images" images={data?.additional_images} setData={setData} data={data} fileCount={3} onFileDrop={handleUploadFiles} />
              <div className="imageDescription">
                <CustomTextField fullWidth name='additional_image_description' value={data?.additional_image_description} onChange={onChange} label='Image Description' placeholder='Image Description' />
              </div>
            </div>
          </div>
        </div>

        <div className='columnOne'>
          <RadioButton name="visibility" value={data?.visibility || 'show'} onChange={onChange} title="Visibility" items={VIEW_STATUS} />
        </div>

        <div className='columnOne'>
          <FormGroup row>
            {NEWS_LISTINGS?.map((item, index) => (
              <CheckboxLabels key={index} onChange={onCheckBoxChange} value={data[item.value]} item={item} />
            ))}
          </FormGroup>
        </div>

        <div className='columnOne'>
          <FormGroup row>
            <CustomSwitch name="is_scheduled" onChange={onToggleChange} label="Scheduled Post" value={data?.is_scheduled} />
            <CustomSwitch name="is_premium" onChange={onToggleChange} label="Make Premium" value={data?.is_premium} />
          </FormGroup>
        </div>

        {(data?.is_featured || data?.is_breaking) &&
          <div className='newsOrderOuter'>
            <div className='columnHalf'>
              {data?.is_featured && <CustomTextField fullWidth name="top_news_order" type="number" required value={data?.top_news_order} onChange={onChange} label='Top News Order' placeholder='Top News Order' />}
            </div>
            <div className='columnHalf'>
              {data?.is_breaking && <CustomTextField fullWidth name="breaking_news_order" type="number" required value={data?.breaking_news_order} onChange={onChange} label='Breaking News Order' placeholder='Breaking News Order' />}
            </div>
          </div>
        }

        {(data?.is_scheduled || scheduledPost) &&
          <div className='columnHalf'>
            <DateTimePickerItem name="schedule_date_time" value={data?.schedule_date_time} onChange={onDateChange} label="News Date and Time" dateTime={true} />
          </div>
        }

        <div className="newsSubmit">
          <Button
            sx={{
              backgroundColor: '#AAAAAA', '&:hover': {
                backgroundColor: '#767676',
              }, color: '#ffffff', padding: '8px 20px', marginRight: '15px'
            }}
            startIcon={<DraftIcon />}
            onClick={() => saveChanges('draft')}
            disabled={loading}
          >
            Save as Draft
          </Button>
          <Button
            sx={{
              backgroundColor: '#7367F0', '&:hover': {
                backgroundColor: '#6056CD',
              }, color: '#ffffff', padding: '8px 20px'
            }}
            startIcon={documentId ? <SaveIcon /> : <AddIcon />}
            onClick={saveChanges}
            disabled={loading}
          >
            {`${documentId ? 'Save' : 'Add'} Post`}
          </Button>
        </div>
      </div>
      <ToastContainer position="bottom-center" autoClose={2000} />
    </div>
  );
}

export default AdNews; 