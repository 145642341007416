import React from "react";
import debounce from 'lodash/debounce';
import Styles from "./Search.module.scss";
import CustomTextField from "../CustomTextField";

function Search({
  searchTerm,
  setSearchTerm,
  placeHolder = "",
  className = "",
  onSearch=() => {},
}) {
  const debouncedHandleInputChange = debounce(onSearch, 500);

  return (
    <div className={className === "filterSearch" ? Styles.filterSearch : Styles.search}>
      {className !== "filterSearch" && <div className={Styles.label}>Search</div>}
      <CustomTextField
        fullWidth
        value={searchTerm}
        label={className === "filterSearch" ? 'Search' : ''}
        onChange={(e) => debouncedHandleInputChange(e.target.value)}
        placeholder={placeHolder} />
    </div>
  );
}

export default Search;
