import React, { forwardRef, useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { SketchPicker } from 'react-color';
import Styles from './TextField.module.scss';

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  alignItems: "flex-start",
  "& .MuiInputLabel-root": {
    transform: "none",
    position: "relative",
    color: 'rgba(47, 43, 61, 0.68)',
    fontFamily: 'Public Sans',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '15px',
  },
  "& .MuiInputBase-root": {
    borderRadius: 8,
    marginTop: '8px',
    backgroundColor: "transparent !important",
    border: `1px solid rgba(47, 43, 61, 0.2)`,
    transition: theme.transitions.create(["border-color", "box-shadow"], {
      duration: theme.transitions.duration.shorter,
    }),
    "&:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error):hover": {
      borderColor: `rgba(47, 43, 61, 0.28)`,
    },
    "&:before, &:after": {
      display: "none",
    },
    "&.MuiInputBase-sizeSmall": {
      borderRadius: 6,
    },
    "&.Mui-error": {
      borderColor: theme.palette.error.main,
    },
    "&.Mui-focused": {
      boxShadow: theme.shadows[2],
      "& .MuiInputBase-input:not(.MuiInputBase-readOnly):not([readonly])::placeholder":
      {
        transform: "translateX(4px)",
      },
      "&.MuiInputBase-colorPrimary": {
        borderColor: theme.palette.primary.main,
      },
      "&.MuiInputBase-colorSecondary": {
        borderColor: theme.palette.secondary.main,
      },
      "&.MuiInputBase-colorInfo": {
        borderColor: theme.palette.info.main,
      },
      "&.MuiInputBase-colorSuccess": {
        borderColor: theme.palette.success.main,
      },
      "&.MuiInputBase-colorWarning": {
        borderColor: theme.palette.warning.main,
      },
      "&.MuiInputBase-colorError": {
        borderColor: theme.palette.error.main,
      },
      "&.Mui-error": {
        borderColor: theme.palette.error.main,
      },
    },
    "&.Mui-disabled": {
      backgroundColor: `${theme.palette.action.selected} !important`,
    },
    "& .MuiInputAdornment-root": {
      marginTop: "0 !important",
    },
  },
  "& .MuiInputBase-input": {
    padding: "7.5px 13px",
    color: 'rgba(47, 43, 61, 0.80)',
    fontFamily: 'Public Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '15px',
    "em": {
      fontStyle: 'normal',
    },
    "&:not(.MuiInputBase-readOnly):not([readonly])::placeholder": {
      transition: theme.transitions.create(["opacity", "transform"], {
        duration: theme.transitions.duration.shorter,
      }),
    },

    // ** For Autocomplete
    "&.MuiInputBase-inputAdornedStart:not(.MuiAutocomplete-input)": {
      paddingLeft: 0,
    },
    "&.MuiInputBase-inputAdornedEnd:not(.MuiAutocomplete-input)": {
      paddingRight: 0,
    },
  },
  "& .MuiFormHelperText-root": {
    margin: theme.spacing(1, 0, 0),
    color: 'rgba(47, 43, 61, 0.40)',
    fontFamily: 'Public Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '15px',
    "&.Mui-error": {
      color: theme.palette.error?.main,
    },
  },

  // ** For Select
  "& .MuiSelect-select:focus, & .MuiNativeSelect-select:focus": {
    backgroundColor: "transparent",
  },
  "& .MuiSelect-filled .MuiChip-root": {
    height: 22,
  },

  // ** For Autocomplete
  "& .MuiAutocomplete-input": {
    paddingLeft: "6px !important",
    paddingTop: "7.5px !important",
    paddingBottom: "7.5px !important",
    "&.MuiInputBase-inputSizeSmall": {
      paddingLeft: "6px !important",
      paddingTop: "2.5px !important",
      paddingBottom: "2.5px !important",
    },
  },
  "& .MuiAutocomplete-inputRoot": {
    paddingTop: "8px !important",
    paddingLeft: "8px !important",
    paddingBottom: "8px !important",
    "&:not(.MuiInputBase-sizeSmall).MuiInputBase-adornedStart": {
      paddingLeft: "13px !important",
    },
    "&.MuiInputBase-sizeSmall": {
      paddingTop: "5px !important",
      paddingLeft: "5px !important",
      paddingBottom: "5px !important",
      "& .MuiAutocomplete-tag": {
        margin: 2,
        height: 22,
      },
    },
  },

  // ** For Textarea
  "& .MuiInputBase-multiline": {
    padding: "15.25px 13px",
    "&.MuiInputBase-sizeSmall": {
      padding: "7.25px 13px",
    },
    "& textarea.MuiInputBase-inputSizeSmall:placeholder-shown": {
      overflowX: "hidden",
    },
  },

  // ** For Date Picker
  "& + .react-datepicker__close-icon": {
    top: 11,
    "&:after": {
      fontSize: "1.6rem !important",
    },
  },
}));

const CustomTextField = forwardRef((props, ref) => {
  const { value, onChange, size = "small", InputLabelProps, color = false, ...rest } = props;
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [pickerColor, setPickerColor] = useState('#ff0000');
  const [textValue, setTextValue] = useState(value);
  const pickerRef = useRef(null);
  const sketchPickerRef = useRef(null);

  const handleColorChange = (newColor) => {
    setPickerColor(newColor.hex);
    onChange('color', newColor.hex);
  };

  const handleDivClick = () => {
    setOpenColorPicker(!openColorPicker);
  };

  const handleOutsideClick = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target) && sketchPickerRef.current && !sketchPickerRef.current.contains(event.target)) {
      setOpenColorPicker(false);
    }
  };

  const handleInputChange = (e) => {
    if (color) onChange('color', e.target.value);
    else onChange(e);
  }

  useEffect(() => {
    setTextValue(value);
    if (color) setPickerColor(value);
  }, [value, color]);

  useEffect(() => {
    if (!color) setTextValue(value);
  }, [value, color]);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className={Styles.textFieldOuter}>
      <TextFieldStyled
        size={size}
        inputRef={ref}
        {...rest}
        variant="filled"
        value={textValue}
        onChange={(e) => {
          if (color) setPickerColor(e.target.value)
          setTextValue(e.target.value);
          handleInputChange(e);
        }}
        InputLabelProps={{ ...InputLabelProps, shrink: true }}
        InputProps={{
          endAdornment: color ? (
            <div ref={pickerRef}
              className={Styles.colorPickBox} 
              onClick={handleDivClick}
              style={{backgroundColor: `${pickerColor}`}}
            >
            </div>
          ) : props.InputProps?.endAdornment
        }}
      />
      {openColorPicker &&
        <div ref={sketchPickerRef}>
          <SketchPicker
            color={pickerColor}
            onChange={handleColorChange}
            className={Styles.customSketchPicker}
          />
        </div>
      }
    </div>
  );
});

export default CustomTextField;
