import React, { useEffect, useState } from "react";
import EditHeader from "../../components/EditHeader";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FileUploader from "../../components/FileUploader";
import CustomTextField from "../../components/CustomTextField";
import Styles from "./ads.module.scss";
import SelectItem from "../../components/Select";
import { AD_SPACES } from "../../utils";
import { useFetchCategoriesQuery } from '../../features/api/categoryApi';
import { useCreateAdvertisementMutation, useFetchAdvertisementDetailQuery, useUpdateAdvertisementMutation } from '../../features/api/advertisementApi';
import { useNavigate, useParams } from 'react-router-dom';
import { appendToFormData, isEmpty, validateData } from '../../helper';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import CheckboxLabels from "../../components/CheckBox";

function CreateEditAd() {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [mobileImage, setMobileImage] = useState(null);
  const [mobile, setMobile] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [loading, setLoading] = useState(false);
  let { id: documentId } = useParams();
  const [data, setData] = useState({
    ad_space: "header",
  });
  const userData = useSelector(state => state?.auth?.user);

  const { data: advertisementData, refetch } = useFetchAdvertisementDetailQuery(documentId);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (!documentId && !userData?.roleData['ads_create']) {
      navigate('/');
    }
    if (documentId && !userData?.roleData['ads_edit']) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.roleData]);

  useEffect(() => {
    setData({ ...data, ...advertisementData?.data })
    if (advertisementData?.data?.mobile_image !== null && advertisementData?.data?.mobile_image!== undefined) {
      console.log(advertisementData?.data, advertisementData?.data?.mobile_image !== null);
      setMobile(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advertisementData]);

  const { data: categoryData } = useFetchCategoriesQuery();

  const [createAdvertisement] = useCreateAdvertisementMutation();
  const [updateAdvertisement] = useUpdateAdvertisementMutation();

  const onChange = (e) => {
    if (e.target.name === "category_id") setSelectedCategory(e.target.value);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleUploadFiles = (name, files) => {
    if (name === "image") setImage(files);
    else setMobileImage(files);
  }

  const saveChanges = async () => {
    setLoading(true);
    const errors = validateData(data, ['ad_space', 'name']);
    if (!isEmpty(errors)) {
      setLoading(false);
      toast.error(errors, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
      return true;
    }

    let postData = data;
    let formData;
    formData = appendToFormData({
      ...postData,
      image: image || data?.image,
      mobile_image: mobileImage?.length ? mobileImage : data?.mobile_image
    });
    let response = {};
    if (documentId) {
      response = await updateAdvertisement(formData);
    } else {
      response = await createAdvertisement(formData);
    }
    if (response?.data?.status) {
      setLoading(false);
      toast.success(response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
      });
      setTimeout(() => {
        navigate('/ads')
      }, 2000);
    } else {
      setLoading(false);
      toast.error(response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
    }
  };

  const mainCategories = categoryData?.data?.filter((item) => !item.parent_id);
  const subCategories = categoryData?.data?.filter((item) => item.parent_id && item.parent_id === selectedCategory);
  const setImageSize = () => {
    switch (data?.ad_space) {
      case "header":
        return ("For optimal display, please upload images with a width of 1008 pixels and height of 93 pixels.");
      case "popUp":
        return ("For optimal display, please upload images with a width of 975 pixels and a minimum height of 251 pixels.");
      case "rightSection":
        return ("For optimal display, please upload images with a width of 380 pixels and a minimum height of 297 pixels.");
      case "inBetweenNewsListing":
        return ("For optimal display, please upload images with a width of 782 pixels and a minimum height of 106 pixels.");
      default:
        return ("For optimal display, please upload images with a width of 1008 pixels and a minimum height of 93 pixels.");
    }
  }

  const setMobileImageSize = () => {
    switch (data?.ad_space) {
      case "header":
        return ("For optimal display, please upload images with a width of 345 pixels and height of 108 pixels.");
      case "popUp":
        return ("For optimal display, please upload images with a width of 300 pixels and a minimum height of 250 pixels.");
      case "rightSection":
        return ("For optimal display, please upload images with a width of 345 pixels and a minimum height of 462 pixels.");
      case "inBetweenNewsListing":
        return ("For optimal display, please upload images with a width of 345 pixels and a minimum height of 63 pixels.");
      default:
        return ("For optimal display, please upload images with a width of 345 pixels and a minimum height of 108 pixels.");
    }
  }
  
  return (
    <div className="container">
      <EditHeader title="Ad Spaces" navigateUrl="/ads" />
      <div className={Styles.adDetails}>
        <SelectItem
          title="Select Ad Space"
          name="ad_space"
          required
          value={data?.ad_space ? AD_SPACES.filter((item) => item.value === data?.ad_space)[0]?.label : ''}
          selector='value'
          items={AD_SPACES}
          placeHolder="Select Ad Space"
          onChange={onChange}
        />
        <SelectItem
          title="Category"
          name="category_id"
          value={data?.category_id ? mainCategories?.filter((item) => item._id === data?.category_id)[0]?.name : ''}
          selector='_id'
          items={mainCategories}
          placeHolder="Select a category"
          onChange={onChange}
        />
        <SelectItem
          title="Sub Category"
          name="subcategory_id"
          value={data?.subcategory_id ? subCategories?.filter((item) => item._id === data?.subcategory_id)[0]?.name : ''}
          selector='_id'
          items={subCategories}
          placeHolder="Select a category"
          onChange={onChange}
        />
      </div>

      <div className={Styles.uploadSection}>
        <div className={Styles.ImageUpload}>
          <div className={Styles.AdsData}>
            <CustomTextField fullWidth name="name" required value={data?.name} onChange={onChange} label='Ad Name' placeholder='Ad Name' />
            <CustomTextField fullWidth name="url" value={data?.url} onChange={onChange} label='Ad Url' placeholder='Ad Url' />
          </div>
          <div style={{ textAlign: 'start', paddingBottom: '5px' }}>{setImageSize()}</div>
          <FileUploader name="image" images={data?.image} setData={setData} data={data} fileCount={1} onFileDrop={handleUploadFiles} />
        </div>
      </div>

      <div className={Styles.userDetails}>
        <CheckboxLabels item={{ name: "", label: "Use seperate image for mobile" }} onChange={() => { setMobile(!mobile) }} value={mobile} />
      </div>

      {mobile &&
        <div className={Styles.uploadSection}>
          <div className={Styles.ImageUpload}>
          <div style={{ textAlign: 'start', paddingBottom: '5px' }}>{setMobileImageSize()}</div>
            <FileUploader name="mobile_image" images={data?.mobile_image} setData={setData} data={data} fileCount={1} onFileDrop={handleUploadFiles} />
          </div>
        </div>
      }

      <div className={Styles.saveAds}>
        <Button
          sx={{
            backgroundColor: '#7367F0', '&:hover': {
              backgroundColor: '#6056CD',
            }, color: '#ffffff', padding: '8px 20px',
            marginLeft: 'auto'
          }}
          startIcon={<AddIcon />}
          onClick={() => saveChanges()}
          disabled={loading}
        >
          Save Changes
        </Button>
      </div>
      <ToastContainer />
    </div>
  );
}

export default CreateEditAd;
