import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { NAVITEMS } from "../../utils/navitems";
import { Link, useLocation } from 'react-router-dom';
import Styles from "./NavBar.module.scss";
import { useSelector } from 'react-redux';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from "@mui/material/styles";

const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#7367f0',
    color: '#fff',
  },
}));

const drawerWidth = 240;

function NavBar(props) {
  const { window, mobileOpen, handleDrawerToggle } = props;
  const userData = useSelector(state => state?.auth?.user);
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };
  const location = useLocation();
  const drawer = (
    <div className="left-navbar">
      <div className={Styles.logoSection} onClick={mobileOpen ? handleDrawerToggle : undefined}>
        <Link to="/" className={Styles.logo1}>
          <img src="/logo-1.png" alt="Logo 1" />
        </Link>
        <Link to="/" className={Styles.logo2}>
          <img src="/logo-2.png" alt="Logo 2" />
        </Link>
      </div>
      <div className="nav-list">
        <List component="nav">
          {NAVITEMS.map((item, index) => {
            const isActive = location.pathname === item.path;
            switch (item.name) {
              case 'dashboard':
                return (
                  <ListItemButtonStyled
                    key={index}
                    component={Link}
                    to={item?.path}
                    onClick={mobileOpen ? handleDrawerToggle : undefined}
                    sx={{ color: isActive ? '#7367f0' : '#000000'}}>
                    <ListItemIcon sx={{ minWidth: '35px' }}>
                      <img src={item?.icon} alt="" />
                    </ListItemIcon>
                    <ListItemText primary={item?.title} />
                  </ListItemButtonStyled>
                )
              case 'addNews':
                return (
                  <div key={index}>
                    {userData?.roleData[`${item?.permission}_create`] &&
                      <ListItemButtonStyled
                        key={index}
                        component={Link}
                        to={item?.path}
                        onClick={mobileOpen ? handleDrawerToggle : undefined}
                        sx={{ color: isActive ? '#7367f0' : '#000000' }}>
                        <ListItemIcon sx={{ minWidth: '35px' }}>
                          <img src={item?.icon} alt="" />
                        </ListItemIcon>
                        <ListItemText primary={item?.title} />
                      </ListItemButtonStyled>
                    }
                  </div>
                )
              default:
                return (
                  <div key={index}>
                    {(userData?.roleData[`${item?.permission}_viewAll`] || userData?.roleData[`${item?.permission}_viewOwn`]) &&
                      <>
                        {item?.children ?
                          <>
                            <ListItemButtonStyled key={index} onClick={handleToggle} >
                              <ListItemIcon sx={{ minWidth: '35px' }} >
                                <img src={item?.icon} alt="" />
                              </ListItemIcon>
                              <ListItemText primary={item?.title} />
                              <ListItemIcon sx={{ justifyContent: 'right' }}>
                                {toggle ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                              </ListItemIcon>
                            </ListItemButtonStyled>
                            {toggle &&
                              <List>
                                {item?.children.map((child, index) => {
                                  const isChildActive = location.pathname === child.path;
                                  return (
                                    <ListItemButtonStyled
                                      key={index}
                                      className={Styles.childNav}
                                      component={Link} to={child?.path}
                                      onClick={mobileOpen ? handleDrawerToggle : undefined}
                                      sx={{ backgroundColor: isChildActive ? '#7367f0' : 'inherit'}}
                                      >
                                      <ListItemIcon sx={{ minWidth: '35px' }}>
                                        <img src={child?.icon} alt="" />
                                      </ListItemIcon>
                                      <ListItemText primary={child?.title} />
                                    </ListItemButtonStyled>
                                  )
                                })}
                              </List>
                            }
                          </> :
                          <ListItemButtonStyled
                            key={index}
                            component={Link}
                            to={item?.path}
                            onClick={mobileOpen ? handleDrawerToggle : undefined}
                            sx={{ color: isActive ? '#7367f0' : '#000000'}}>
                            <ListItemIcon sx={{ minWidth: '35px' }}>
                              <img src={item?.icon} alt="" />
                            </ListItemIcon>
                            <ListItemText primary={item?.title} />
                          </ListItemButtonStyled>
                        }
                      </>
                    }
                  </div>
                )
            }
          })}
        </List>
      </div>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

NavBar.propTypes = {
  window: PropTypes.func,
};

export default NavBar;