// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roles_roleContent__rY2tJ {
  width: 50%;
  display: flex;
  padding: 15px 0;
}

.roles_roleDetails__aSJO8 {
  padding: 15px 0;
}
.roles_roleDetails__aSJO8 table {
  border: 1px solid rgba(47, 43, 61, 0.2);
  border-radius: 5px;
  width: 72%;
  letter-spacing: 1px;
  border-collapse: collapse;
}
.roles_roleDetails__aSJO8 table thead {
  background-color: #7367F0;
  color: #ffffff;
}
.roles_roleDetails__aSJO8 table thead th {
  font-size: 20px;
  font-weight: normal;
  padding: 5px 15px;
  border: 1px solid rgba(47, 43, 61, 0.2);
  text-align: left;
}
.roles_roleDetails__aSJO8 table thead th:first-child {
  border-radius: 5px 0 0 5px;
}
.roles_roleDetails__aSJO8 table thead th:last-child {
  border-radius: 0 5px 5px 0;
}
.roles_roleDetails__aSJO8 table tbody td {
  border: 1px solid rgba(47, 43, 61, 0.2);
  text-align: left;
  padding: 5px 15px;
}
@media screen and (max-width: 768px) {
  .roles_roleDetails__aSJO8 table {
    width: 100%;
  }
}

.roles_saveRoles__7njiL {
  display: flex;
  padding-bottom: 30px;
}

@media screen and (max-width: 600px) {
  .roles_saveRoles__7njiL {
    justify-content: center;
  }
  .roles_saveRoles__7njiL button {
    margin: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/roles/roles.module.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,aAAA;EACA,eAAA;AACJ;;AAEA;EACI,eAAA;AACJ;AACI;EACI,uCAAA;EACA,kBAAA;EACA,UAAA;EACA,mBAAA;EACA,yBAAA;AACR;AACQ;EACI,yBAAA;EACA,cAAA;AACZ;AACY;EACI,eAAA;EACA,mBAAA;EACA,iBAAA;EACA,uCAAA;EACA,gBAAA;AAChB;AACgB;EACI,0BAAA;AACpB;AAEgB;EACI,0BAAA;AAApB;AAMY;EACI,uCAAA;EACA,gBAAA;EACA,iBAAA;AAJhB;AASI;EACI;IACI,WAAA;EAPV;AACF;;AAWA;EACI,aAAA;EACA,oBAAA;AARJ;;AAUA;EACI;IACI,uBAAA;EAPN;EAQM;IACI,SAAA;EANV;AACF","sourcesContent":[".roleContent {\n    width: 50%;\n    display: flex;\n    padding: 15px 0;\n}\n\n.roleDetails {\n    padding: 15px 0;\n\n    table {\n        border: 1px solid rgba(47, 43, 61, 0.2);\n        border-radius: 5px;\n        width: 72%;\n        letter-spacing: 1px;\n        border-collapse: collapse;\n\n        thead {\n            background-color: #7367F0;\n            color: #ffffff;\n\n            th {\n                font-size: 20px;\n                font-weight: normal;\n                padding: 5px 15px;\n                border: 1px solid rgba(47, 43, 61, 0.2);\n                text-align: left;\n\n                &:first-child {\n                    border-radius: 5px 0 0 5px;\n                }\n\n                &:last-child {\n                    border-radius: 0 5px 5px 0;\n                }\n            }\n        }\n\n        tbody {\n            td {\n                border: 1px solid rgba(47, 43, 61, 0.2);\n                text-align: left;\n                padding: 5px 15px;\n            }\n        }\n    }\n\n    @media screen and (max-width: 768px) {\n        table {\n            width: 100%;\n        }\n    }\n}\n\n.saveRoles {\n    display: flex;\n    padding-bottom: 30px;\n}\n@media screen and (max-width: 600px) {\n    .saveRoles {\n        justify-content: center;\n        button{\n            margin: 0;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"roleContent": `roles_roleContent__rY2tJ`,
	"roleDetails": `roles_roleDetails__aSJO8`,
	"saveRoles": `roles_saveRoles__7njiL`
};
export default ___CSS_LOADER_EXPORT___;
