// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DatePickerItem_DateTimePicker__dLV68 {
  width: 100%;
  text-align: left;
}
.DatePickerItem_DateTimePicker__dLV68 input {
  padding: 8.5px 14px;
  color: rgba(47, 43, 61, 0.4);
  font-family: Public Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}
.DatePickerItem_DateTimePicker__dLV68 p {
  color: rgba(47, 43, 61, 0.68);
  font-family: Public Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}
.DatePickerItem_DateTimePicker__dLV68 .DatePickerItem_datePickerForm__l04y- {
  margin-top: 8px;
}
.DatePickerItem_DateTimePicker__dLV68 .DatePickerItem_fullWidth__3qyNb {
  width: 100%;
}
@media screen and (max-width: 600px) {
  .DatePickerItem_DateTimePicker__dLV68 {
    padding-bottom: 30px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/DatePicker/DatePickerItem.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;AACF;AACE;EACE,mBAAA;EACA,4BAAA;EACA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AAEE;EACE,6BAAA;EACA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;AAGE;EACE,eAAA;AADJ;AAGE;EACE,WAAA;AADJ;AAGE;EA7BF;IA8BK,oBAAA;EAAH;AACF","sourcesContent":[".DateTimePicker {\n  width: 100%;\n  text-align: left;\n\n  input {\n    padding: 8.5px 14px;\n    color: rgba(47, 43, 61, 0.40);\n    font-family: Public Sans;\n    font-size: 13px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 15px;\n  }\n\n  p {\n    color: rgba(47, 43, 61, 0.68);\n    font-family: Public Sans;\n    font-size: 15px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 15px;\n  }\n\n  .datePickerForm {\n    margin-top: 8px;\n  }\n  .fullWidth {\n    width: 100%;\n  }\n  @media screen and (max-width: 600px) {\n     padding-bottom: 30px;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DateTimePicker": `DatePickerItem_DateTimePicker__dLV68`,
	"datePickerForm": `DatePickerItem_datePickerForm__l04y-`,
	"fullWidth": `DatePickerItem_fullWidth__3qyNb`
};
export default ___CSS_LOADER_EXPORT___;
