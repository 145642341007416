import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setAuthHeader } from './apiHelpers';
import { liveAppUrl, demoAppUrl, localAppUrl } from '../../config';

const currentURL = window.location.href;
const appURL = currentURL.includes('localhost') ? localAppUrl : currentURL.includes('glidix') ? demoAppUrl : liveAppUrl;

export const gameApi = createApi({
  reducerPath: 'gameApi',
  baseQuery: fetchBaseQuery({
    baseUrl: appURL,
    prepareHeaders: (headers) => {
      setAuthHeader(headers);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchGame: builder.query({
      query: (params) => {
        if (params?.search) {
          return `/game/list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}&search=${params.search}`;
        } else {
          return `/game/list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}`;
        }
      }
    }),
    fetchGameDetail: builder.query({
      query: (id) => `/game/detail/${id}`,
    }),
    createGame: builder.mutation({
      query: (credentials) => ({
        url: '/game/store',
        method: 'POST',
        body: credentials,
      }),
    }),
    updateGame: builder.mutation({
      query: (credentials) => ({
        url: '/game/update',
        method: 'POST',
        body: credentials,
      }),
    }),
    updateGameStatus: builder.mutation({
      query: (credentials) => ({
        url: '/game/update-status',
        method: 'PUT',
        body: credentials,
      }),
    }),
    deleteGame: builder.mutation({
      query: (id) => ({
        url: `/game/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchGameQuery,
  useFetchGameDetailQuery,
  useCreateGameMutation,
  useUpdateGameMutation,
  useUpdateGameStatusMutation,
  useDeleteGameMutation
} = gameApi;
