import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setAuthHeader } from './apiHelpers';
import { liveAppUrl, demoAppUrl, localAppUrl } from '../../config';

const currentURL = window.location.href;
const appURL = currentURL.includes('localhost') ? localAppUrl : currentURL.includes('glidix') ? demoAppUrl : liveAppUrl;

export const careerApi = createApi({
  reducerPath: 'careerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: appURL,
    prepareHeaders: (headers) => {
      setAuthHeader(headers);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchCareers: builder.query({
      query: (params) => {
        if (params?.filter) {
          return `/career/list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}&filter=${params?.filter}`;
        } else if (params?.search) {
          return `/career/list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}&search=${params.search}`;
        } else {
          return `/career/list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}`;
        }
      }
    }),
    fetchCareerDetail: builder.query({
      query: (id) => `/career/detail/${id}`,
    }),
    createCareer: builder.mutation({
      query: (credentials) => ({
        url: '/career/store',
        method: 'POST',
        body: credentials,
      }),
    }),
    updateCareer: builder.mutation({
      query: (credentials) => ({
        url: '/career/update',
        method: 'POST',
        body: credentials,
      }),
    }),
    deleteCareer: builder.mutation({
      query: (id) => ({
        url: `/career/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchCareersQuery,
  useFetchCareerDetailQuery,
  useCreateCareerMutation,
  useUpdateCareerMutation,
  useDeleteCareerMutation
} = careerApi;
