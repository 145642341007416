import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

function CheckboxLabels({ item, onChange, value = '', disabled = false }) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          name={item.value}
          sx={{
            color: 'rgba(47, 43, 61, 0.20);',
            fontFamily: 'Public Sans',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '15px'
          }}
          disabled={disabled}
          checked={value}
          onChange={onChange}
        />
      }
      label={item.label}
    />
  );
}

export default CheckboxLabels;
