import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";

function RadioButton({ name, title, items, value, onChange = () => { } }) {
  return (
    <div className="radioSection">
      <p>{title}</p>
      <RadioGroup
        row
        aria-label="sizes"
        name={name}
        value={value}
        onChange={onChange}
      >
        {items.map((item) => (
          <FormControlLabel
            key={item.value}
            value={item.value}
            control={<Radio />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </div>
  );
}

export default RadioButton;
