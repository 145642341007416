import React from "react";

export default function pauseiconDisabled() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
            <path d="M4.33333 1H1V14H4.33333V1Z" stroke="#424446" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11 1H7.66667V14H11V1Z" stroke="#424446" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}
