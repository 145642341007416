import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetchMessageDetailQuery } from '../../features/api/messageApi';
import EditHeader from '../../components/EditHeader';
import CustomTextField from '../../components/CustomTextField';
import TextArea from '../../components/TextArea';

function MessageView() {
  const navigate = useNavigate();
  let { id: documentId } = useParams();
  const [data, setData] = useState({});

  const userRoleData = useSelector(state => state?.auth?.user);
  const { data: messageData, refetch } = useFetchMessageDetailQuery(documentId);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (!documentId && !userRoleData?.roleData['messages_create']) {
      navigate('/');
    }
    if (documentId && !userRoleData?.roleData['messages_edit']) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRoleData?.roleData]);

  useEffect(() => {
    setData({ ...data, ...messageData?.data })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageData]);

  const onChange = (e) => {
  };

  return (
    <div className="container">
      <EditHeader title="Message Details" />
      <div className="detailSection">
        <div className='columnThree'>
          <CustomTextField fullWidth name='name' disabled value={data?.name} label='Name' placeholder='Name' />
          <CustomTextField fullWidth name='mobile' disabled value={data?.mobile} label='Mobile' placeholder='Mobile' />
          <CustomTextField fullWidth name='email' disabled value={data?.email} label='Email' placeholder='Email' />
        </div>
        <div className='columnOne'>
          <CustomTextField fullWidth name='subject' disabled value={data?.subject} label='Subject' placeholder='Subject' />
        </div>
        <div className='columnOne'>
          <TextArea rowCount={4} name="message" disabled value={data?.message} onChange={onChange} label="Message" placeholder="Message" />
        </div>
      </div>
    </div>
  );
}

export default MessageView;