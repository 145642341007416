import React from "react";

export default function cancel() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <rect width="32" height="32" rx="16" fill="white" />
      <path
        d="M22 10L10 22M10 10L22 22"
        stroke="#7367F0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
