import React, { useEffect, useState } from "react";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from '@mui/icons-material/Save';
import EditHeader from "../../components/EditHeader";
import CustomTextField from "../../components/CustomTextField";
import SelectItem from '../../components/Select';
import Styles from "./user.module.scss";
import { useFetchUserDetailQuery, useCreateUserMutation, useUpdateUserMutation } from '../../features/api/userApi';
import { useFetchRoleQuery } from '../../features/api/roleApi';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { isEmpty, validateData } from "../../helper";
import { useSelector } from "react-redux";

function CreateEditUser() {
  const navigate = useNavigate();
  let { id: documentId } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    type: "admin",
    subscribed: false,
  });
  const userRoleData = useSelector(state => state?.auth?.user);

  const { data: userData, refetch } = useFetchUserDetailQuery(documentId);
  const { data: roleData } = useFetchRoleQuery();

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (!documentId && !userRoleData?.roleData['users_create']) {
      navigate('/');
    }
    if (documentId && !userRoleData?.roleData['users_edit']) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRoleData?.roleData]);

  useEffect(() => {
    setData({ ...data, ...userData?.data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const [createUser] = useCreateUserMutation();
  const [updateUser] = useUpdateUserMutation();

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onCheckBoxChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.checked });
  };

  const saveChanges = async () => {
    setLoading(true);
    const errors = validateData(data, documentId ? ['name', 'email', 'mobile', 'role'] : ['name', 'email', 'mobile', 'role', 'password']);
    if (!isEmpty(errors)) {
      setLoading(false);
      toast.error(errors, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
      return true;
    }

    let response = {};
    if (documentId) {
      response = await updateUser(data);
    } else {
      response = await createUser(data);
    }
    if (response?.data?.status) {
      setLoading(false);
      toast.success(response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
      });
      setTimeout(() => {
        navigate('/users')
      }, 2000);
    } else {
      setLoading(false);
      toast.error(response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
    }
  };

  return (
    <div className="container">
      <EditHeader title="Add User" navigateUrl="/users" />
      <div className={Styles.userDetails}>
        <CustomTextField fullWidth name='name' required value={data?.name} onChange={onChange} label='Name' placeholder='Name' />
        <CustomTextField fullWidth name='email' required value={data?.email} onChange={onChange} label='E-Mail' placeholder='Email' />
      </div>
      <div className={Styles.userDetails}>
      <CustomTextField fullWidth name='description' value={data?.description} onChange={onChange} label='Short Description' placeholder='Short Description' />
        <CustomTextField className="noArrows" fullWidth name='mobile' type="number" required value={data?.mobile} onChange={onChange} label='Mobile' placeholder='Mobile' />
        <SelectItem
          title="Role"
          name="role"
          required
          value={data?.role ? roleData?.data?.filter((item) => item.type === data?.role)[0]?.name : ''}
          selector="type"
          items={roleData?.data}
          placeHolder="Role"
          onChange={onChange}
        />
         <CustomTextField fullWidth name='password' required={!documentId}  onChange={onChange} label='Password' placeholder='Password' />
      </div>
      <h4 className={Styles.socialHeading}>Social Media Links</h4>
      <div className={Styles.userDetails}>
        <CustomTextField fullWidth name='whatsapp' required value={data?.whatsapp} onChange={onChange} label='Whatsapp' placeholder='Whatsapp' />
        <CustomTextField fullWidth name='facebook' required value={data?.facebook} onChange={onChange} label='Facebook' placeholder='Facebook' />
        <CustomTextField fullWidth name='instagram' required value={data?.instagram} onChange={onChange} label='Instagram' placeholder='Instagram' />
        <CustomTextField fullWidth name='twitter' required value={data?.twitter} onChange={onChange} label='Twitter' placeholder='Twitter' />
      </div>
      <div className={Styles.userDetails}>
        <FormControlLabel control={<Checkbox
          name="subscribed"
          sx={{
            color: 'rgba(47, 43, 61, 0.20);',
            fontFamily: 'Public Sans',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '15px'
          }}
          checked={data?.subscribed}
          onChange={onCheckBoxChange}
        />}
          label="Subscribed"
        />
      </div>
      <div className={Styles.userSubmit}>
        <Button
          sx={{
            backgroundColor: '#7367F0', '&:hover': {
              backgroundColor: '#6056CD',
            }, color: '#ffffff', padding: '8px 20px'
          }}
          startIcon={documentId ? <SaveIcon /> : <AddIcon />}
          onClick={() => saveChanges()}
          disabled={loading}
        >
          {`${documentId ? 'Update' : 'Add'} User`}
        </Button>
      </div>
      <ToastContainer />
    </div>
  );
}

export default CreateEditUser;
