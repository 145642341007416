// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableTopbar_tableTopbar__\\+w4YQ {
  display: flex;
  padding: 15px 10px;
}
.TableTopbar_tableTopbar__\\+w4YQ .TableTopbar_pageSelector__FDzfi {
  display: flex;
  align-items: center;
}
.TableTopbar_tableTopbar__\\+w4YQ .TableTopbar_pageSelector__FDzfi .TableTopbar_label__Qcq3t {
  margin: 8px 10px 0 0;
}
@media screen and (max-width: 600px) {
  .TableTopbar_tableTopbar__\\+w4YQ {
    display: block;
  }
  .TableTopbar_tableTopbar__\\+w4YQ .TableTopbar_pageSelector__FDzfi .TableTopbar_label__Qcq3t {
    margin: 0 10px 0 0;
    padding-bottom: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/TableTopbar/TableTopbar.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;AACJ;AACI;EACE,oBAAA;AACN;AAGE;EAbF;IAcI,cAAA;EAAF;EAGI;IACE,kBAAA;IACA,oBAAA;EADN;AACF","sourcesContent":[".tableTopbar {\n  display: flex;\n  padding: 15px 10px;\n\n  .pageSelector {\n    display: flex;\n    align-items: center;\n\n    .label {\n      margin: 8px 10px 0 0;\n    }\n  }\n\n  @media screen and (max-width: 600px) {\n    display: block;\n\n    .pageSelector {\n      .label {\n        margin: 0 10px 0 0;\n        padding-bottom: 20px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableTopbar": `TableTopbar_tableTopbar__+w4YQ`,
	"pageSelector": `TableTopbar_pageSelector__FDzfi`,
	"label": `TableTopbar_label__Qcq3t`
};
export default ___CSS_LOADER_EXPORT___;
