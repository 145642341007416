import React, { useEffect, useState } from "react";
import TableComponent from "../../components/Table";
import { useFetchPageQuery, useDeletePageMutation } from '../../features/api/pageApi';
import { useNavigate } from 'react-router-dom';
import { LOCAITIONS } from "../../utils";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";

const headers = [
  { title: "#", key: "id", type: "id" },
  { title: "TITLE", key: "title", type: "text" },
  { title: "LOCATION", key: "menu_visibility", type: "filter", array: LOCAITIONS },
  { title: "VISIBILITY", key: "visibility", type: "visibility" },
  { title: "PAGE TYPE", key: "page_type", type: "text" },
  { title: "DATE ADDED", key: "created_at", type: "date" },
];

function Pages() {
  const navigate = useNavigate();
  const [params, setParams] = useState({ page: 1, perPage: 10 });
  const { data, refetch } = useFetchPageQuery(params);
  const userData = useSelector(state => state?.auth?.user);

  useEffect(() => {
    refetch();
  }, [refetch, params]);

  useEffect(() => {
    if (!(userData?.roleData['pages_viewAll'] || userData?.roleData['pages_viewOwn'])) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.roleData]);

  const [deletePage] = useDeletePageMutation();

  const handleEdit = (id) => {
    navigate(`/pages/edit/${id}`);
  }
  const handleDelete = async (id) => {
    const response = await deletePage(id);
    refetch();
    if (response?.data?.status) {
      toast.success(response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
    }
  }

  const onSearch = (value) => {
    setParams({ ...params, search: value, page: 1 })
  }

  const onPaginationCountChange = (value) => {
    setParams({ ...params, perPage: value })
  }

  const onPaginationChange = (value) => {
    setParams({ ...params, page: value })
  }

  return (
    <div className="container">
      <div className="table-listing">
        <TableComponent
          title="Pages"
          defaultTitle={true}
          headers={headers}
          data={data?.data?.list}
          metaData={data?.data?.meta}
          rows={10}
          buttonContent="Add Pages"
          view={false}
          add={true}
          buttonStatus={true}
          actions={[{ type: 'edit', role: 'edit', onClick: handleEdit }, { type: 'delete', role: 'delete', onClick: handleDelete }]}
          permissionPath="pages"
          currentPage={params?.page}
          onPaginationChange={onPaginationChange}
          onSearch={onSearch}
          onPaginationCountChange={onPaginationCountChange}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default Pages;
