// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Search_search__JtbP4 {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.Search_search__JtbP4 .Search_label__2bPAf {
  margin: 8px 10px 0 0;
}
@media screen and (max-width: 600px) {
  .Search_search__JtbP4 .Search_label__2bPAf {
    margin: 0 10px 0 0;
    padding-bottom: 20px;
  }
}

.Search_filterSearch__2g0u\\+ {
  width: 20%;
  padding-right: 3%;
  text-align: left;
}
.Search_filterSearch__2g0u\\+ input {
  padding: 8.5px 14px;
}
.Search_filterSearch__2g0u\\+ .Search_MuiFormControl-root__gT6Zx {
  margin-top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/Search/Search.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;AACF;AACE;EACE,oBAAA;AACJ;AACE;EACE;IACE,kBAAA;IACA,oBAAA;EACJ;AACF;;AAGA;EACE,UAAA;EACA,iBAAA;EACA,gBAAA;AAAF;AAEE;EACE,mBAAA;AAAJ;AAGE;EACE,eAAA;AADJ","sourcesContent":[".search {\n  display: flex;\n  align-items: center;\n  margin-left: auto;\n\n  .label {\n    margin: 8px 10px 0 0;\n  }\n  @media screen and (max-width: 600px) {\n    .label {\n      margin: 0 10px 0 0;\n      padding-bottom: 20px;\n    }\n  }\n}\n\n.filterSearch {\n  width: 20%;\n  padding-right: 3%;\n  text-align: left;\n\n  input {\n    padding: 8.5px 14px;\n  }\n\n  .MuiFormControl-root {\n    margin-top: 8px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": `Search_search__JtbP4`,
	"label": `Search_label__2bPAf`,
	"filterSearch": `Search_filterSearch__2g0u+`,
	"MuiFormControl-root": `Search_MuiFormControl-root__gT6Zx`
};
export default ___CSS_LOADER_EXPORT___;
