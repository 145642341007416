import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateUserMutation } from '../features/api/userApi';
import { ToastContainer, toast } from 'react-toastify';
import { isEmpty, validateData } from "../helper";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from '@mui/material';
import CustomTextField from '../components/CustomTextField';
import { GENDER } from "../utils";
import SelectItem from '../components/Select';
import { appendToFormData } from '../helper';
import { imageBaseUrl, imageLiveBaseUrl } from "../config";

const currentURL = window.location.href;
const imageUrl = currentURL.includes('nattuvartha') ? imageLiveBaseUrl : imageBaseUrl;

function Profile() {
  const userData = useSelector(state => state?.auth?.user);
  const [data, setData] = useState(userData);
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(`${imageUrl}${userData?.image}` || '/avatar.png');
  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleInputImageChange = (e) => {
    const reader = new FileReader()
    const file = e.target?.files[0];
    reader.onload = () => setInputValue(reader.result);
    reader.readAsDataURL(file);
    setImage(file);
  }

  const [updateUser] = useUpdateUserMutation();

  const saveChanges = async () => {
    setLoading(true);
    const errors = validateData(data, ['name', 'email', 'mobile']);
    if (!isEmpty(errors)) {
      setLoading(false);
      toast.error(errors, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
      return true;
    }

    let formData;
    formData = appendToFormData({...data, image: image || data?.image, page_type: 'profile' });
    const response = await updateUser(formData);
    if (response?.data?.status) {
      setLoading(false);
      toast.success('Profile Updated successfully', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
    }
  };

  return (
    <div className="container">
      <Grid item xs={12}>
        <Card sx={{ boxShadow: 'none' }}>
          <CardHeader title='Profile Details' />
          <form>
            <CardContent sx={{ pt: 0 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img className='profileImage' src={inputValue} alt='Profile Pic' />
                <div>
                  <Button component='label' variant='contained' htmlFor='account-settings-upload-image'>
                    Upload New Photo
                    <input
                      hidden
                      type='file'
                      // value={inputValue}
                      accept='.jpg, .jpeg, .png'
                      onChange={handleInputImageChange}
                      id='account-settings-upload-image'
                    />
                  </Button>
                </div>
              </Box>
            </CardContent>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6}>
                  <CustomTextField fullWidth name='name' required value={data?.name} onChange={onChange} label='Name' placeholder='Name' />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField fullWidth name='email' required value={data?.email} onChange={onChange} label='Email' placeholder='Email' />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField className="noArrows" required fullWidth name='mobile' type='number' label='Mobile' value={data?.mobile} placeholder='Mobile' onChange={onChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SelectItem
                    title="Gender"
                    name="gender"
                    value={data?.gender ? GENDER.filter((item) => item.value === data?.gender)[0]?.label : ''}
                    selector='value'
                    items={GENDER}
                    placeHolder="Gender"
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField className="noArrows" autoComplete="new-password" fullWidth name='password' type='password' label='Password'  placeholder='Password' onChange={onChange} />
                </Grid>
                <Grid item xs={12} sx={{ pt: theme => `${theme.spacing(6.5)} !important` }}>
                  <Button variant='contained' sx={{ mr: 4 }} disabled={loading} onClick={() => saveChanges()}>
                    Save Changes
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </form>
        </Card>
      </Grid>
      <ToastContainer />
    </div>
  );
}

export default Profile;