// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination_paginationOuter__NjwxJ {
  min-height: 52px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  padding-right: 2px;
  padding-left: 24px;
  align-items: center;
  display: flex;
  position: relative;
}
.pagination_paginationOuter__NjwxJ .pagination_spacer__9DWs8 {
  flex: 1 1 100%;
}
.pagination_paginationOuter__NjwxJ .pagination_displayedRows__--EBQ {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  flex-shrink: 0;
}
.pagination_paginationOuter__NjwxJ .pagination_actions__R\\+vfS {
  flex-shrink: 0;
  margin-left: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Pagination/pagination.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,0BAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,kBAAA;AACJ;AAAI;EACI,cAAA;AAER;AAAI;EACI,uDAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,yBAAA;EAGA,cAAA;AAER;AAAI;EACI,cAAA;EACA,iBAAA;AAER","sourcesContent":[".paginationOuter {\n    min-height: 52px;\n    color: rgba(0, 0, 0, 0.87);\n    font-size: 0.875rem;\n    padding-right: 2px;\n    padding-left: 24px;\n    align-items: center;\n    display: flex;\n    position: relative;\n    .spacer {\n        flex: 1 1 100%;\n    }\n    .displayedRows {\n        font-family: \"Roboto\",\"Helvetica\",\"Arial\",sans-serif;\n        font-weight: 400;\n        font-size: 0.875rem;\n        line-height: 1.43;\n        letter-spacing: 0.01071em;\n        -webkit-flex-shrink: 0;\n        -ms-flex-negative: 0;\n        flex-shrink: 0;\n    }\n    .actions {\n        flex-shrink: 0;\n        margin-left: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationOuter": `pagination_paginationOuter__NjwxJ`,
	"spacer": `pagination_spacer__9DWs8`,
	"displayedRows": `pagination_displayedRows__--EBQ`,
	"actions": `pagination_actions__R+vfS`
};
export default ___CSS_LOADER_EXPORT___;
