import React from "react";
import { MenuItem, FormControl, InputLabel } from "@mui/material";
import CustomTextField from "../CustomTextField";

function SelectItem({
  value,
  items,
  title,
  placeHolder,
  required = false,
  name = "",
  onChange = () => { },
  selector = "_id",
  noneItem = false,
  sx = {}
}) {
  return (
    <div className="selectLabel">
      <InputLabel sx={{
        color: 'rgba(47, 43, 61, 0.68)',
        fontFamily: 'Public Sans',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '15px'
      }}>{title}{required ? ' *' : ''}</InputLabel>
      <FormControl sx={{ m: 1, minWidth: 120, width: "100%", margin: '8px 0 0 0' }}>
        <CustomTextField
          select
          name={name}
          fullWidth
          defaultValue='Select Role'
          SelectProps={{
            value: value,
            displayEmpty: true,
            onChange: onChange,
            renderValue: selected => {
              if (selected?.length === 0) {
                return <em>{placeHolder}</em>
              }

              return selected;
            }
          }}
          style={sx}
        >
          {noneItem && (
            <MenuItem value="none">
              <em>None</em>
            </MenuItem>
          )}
          {items &&
            items?.map((item, index) => (
              <MenuItem key={index} value={item[selector]}>
                {item?.name || item?.title || item?.label}
              </MenuItem>
            ))}
        </CustomTextField>
      </FormControl>
    </div >
  );
}

export default SelectItem;
