import React, { useEffect, useState } from 'react';
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EditHeader from "../../components/EditHeader";
import CustomTextField from "../../components/CustomTextField";
import SelectItem from '../../components/Select';
import TextArea from '../../components/TextArea';
import EditorComponent from '../../components/Editor';
import RadioButton from "../../components/RadioButton";
import CustomSwitch from "../../components/CustomSwitch";
import { LOCAITIONS, VIEW_STATUS } from "../../utils";
import { FormGroup } from '@mui/material';
import { useFetchNavigationsQuery } from '../../features/api/navigationApi';
import { useCreatePageMutation, useFetchPageDetailQuery, useUpdatePageMutation } from '../../features/api/pageApi';
import { useNavigate, useParams } from 'react-router-dom';
import TagBox from '../../components/Tag';
import { ToastContainer, toast } from 'react-toastify';
import { isEmpty, validateData } from '../../helper';
import { useSelector } from 'react-redux';

function CreateEditPage() {
  const navigate = useNavigate();
  let { id: documentId } = useParams();
  const [tagValue, setTagValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    for_registered_user: false,
    show_title: false,
    show_breadcrum: false,
    show_right_column: false,
    menu_visibility: "top",
    visibility: "show",
    date_time: new Date().toISOString(),
    tags: []
  });
  const userData = useSelector(state => state?.auth?.user);

  const { data: pageData, refetch } = useFetchPageDetailQuery(documentId);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (!documentId && !userData?.roleData['pages_create']) {
      navigate('/');
    }
    if (documentId && !userData?.roleData['pages_edit']) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.roleData]);

  useEffect(() => {
    setData({ ...data, ...pageData?.data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData]);

  const { data: navigationData } = useFetchNavigationsQuery();

  const [createNews] = useCreatePageMutation();
  const [updateNews] = useUpdatePageMutation();

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onToggleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.checked });
  };

  const handleHtmlChange = (newHtmlContent) => {
    setData({ ...data, content: `${newHtmlContent}` });
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (!data.tags.includes(tagValue)) {
        setData({ ...data, tags: [...data.tags, tagValue] });
      }
      setTagValue('');
    }
  };

  const handleAddTag = () => {
    if (!data.tags.includes(tagValue)) {
      setData({ ...data, tags: [...data.tags, tagValue] });
    }
    setTagValue('');
  };

  const handleTagCancel = (tagToRemove) => {
    setData({ ...data, tags: data?.tags.filter((item) => item !== tagToRemove) });
  };

  const saveChanges = async () => {
    setLoading(true);
    const errors = validateData(data, ['title', 'slug', 'content']);
    if (!isEmpty(errors)) {
      setLoading(false);
      toast.error(errors, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
      return true;
    }

    let response = {};
    if (documentId) {
      response = await updateNews(data);
    } else {
      response = await createNews(data);
    }
    if (response?.data?.status) {
      setLoading(false);
      toast.success(response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
      });
      setTimeout(() => {
        navigate('/pages')
      }, 2000);
    } else {
      setLoading(false);
      toast.error(response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
    }
  };

  const onBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="container">
      <EditHeader title="Add Page" navigateUrl="/pages" />
      <div className="detailSection">
        <div className='columnOne'>
          <CustomTextField fullWidth name='title' required value={data?.title} onChange={onChange} label='Title' placeholder='Title' />
        </div>

        <div className='columnOne'>
          <CustomTextField fullWidth name='slug' disabled={documentId ? true : false} value={data?.slug} onChange={onChange} label='Slug (If you leave it blank, it will be generated automatically.)' placeholder='Slug' />
        </div>

        <div className="columnOne">
          <TextArea rowCount={4} name="meta_desc" value={data?.meta_desc} onChange={onChange} label="Meta Description" placeholder="Meta Description" defaultValue="" />
        </div>

        <div className="columnOne">
          <CustomTextField fullWidth name="keywords" value={data?.keywords} onChange={onChange} label='Meta Keywords' placeholder='Meta Keywords' />
        </div>

        <div className='columnOne'>
          <div className='tagParent'>
            <CustomTextField
              fullWidth
              name='tags'
              value={tagValue}
              onChange={(e) => setTagValue(e.target.value)}
              onKeyUp={handleKeyUp}
              label='Tags'
              placeholder='Add a tag'
              helperText='(Type tag and hit enter)'
            />
            <Button
              className='addTagMobile'
              onClick={handleAddTag}
              size='small'
              sx={{
                backgroundColor: '#7367F0',
                '&:hover': {
                  backgroundColor: '#6056CD',
                },
                color: '#ffffff',
                padding: '8px 20px',
                height: '35px',
                marginLeft: '10px',
                top: '23px',
              }}
            >Add Tag</Button>
          </div>
          {data?.tags && <div className="tagOuter">
            {data?.tags.map((tag, index) => (
              <TagBox key={index} tag={tag} handleTagCancel={handleTagCancel} />
            ))}
          </div>}
        </div>

        <div className="columnTwo">
          <SelectItem
            title="Parent Link"
            name="parent_id"
            value={data?.parent_id ? navigationData?.data?.filter((item) => item._id === data?.parent_id)[0]?.title : ''}
            selector='_id'
            items={navigationData?.data}
            placeHolder="Select a Link"
            onChange={onChange}
          />
          <CustomTextField fullWidth name="menu_order" type="number" value={data?.menu_order} onChange={onChange} label='Menu Order' placeholder='Menu Order' />
        </div>

        <div className='columnOne'>
          <EditorComponent name="content" required value={data?.content} onChange={handleHtmlChange} title="Content *" />
        </div>

        <div className='columnOne'>
          <RadioButton title="Location" value={data?.menu_visibility || 'top'} onChange={onChange} name="menu_visibility" items={LOCAITIONS} />
        </div>

        <div className='columnOne'>
          <RadioButton title="Visibility" value={data?.visibility || 'show'} onChange={onChange} name="visibility" items={VIEW_STATUS} />
        </div>

        <div className='columnOne'>
          <FormGroup row>
            <CustomSwitch name="for_registered_user" onChange={onToggleChange} label="Show Only to Registerd Users" value={data?.for_registered_user} />
            <CustomSwitch name="show_title" onChange={onToggleChange} label="Show Title" value={data?.show_title} />
            <CustomSwitch name="show_breadcrum" onChange={onToggleChange} label="Show Breadcrumb" value={data?.show_breadcrum} />
            <CustomSwitch name="show_right_column" onChange={onToggleChange} label="Show Right Column" value={data?.show_right_column} />
          </FormGroup>
        </div>
        <div className="newsSubmit">
          <Button
            sx={{
              backgroundColor: '#AAAAAA', '&:hover': {
                backgroundColor: '#767676',
              }, color: '#ffffff', padding: '8px 20px', marginRight: '15px'
            }}
            startIcon={<KeyboardBackspaceIcon />}
            onClick={onBackClick}
            disabled={loading}
          >
            Back
          </Button>
          <Button
            sx={{
              backgroundColor: '#7367F0', '&:hover': {
                backgroundColor: '#6056CD',
              }, color: '#ffffff', padding: '8px 20px'
            }}
            startIcon={documentId ? <SaveIcon /> : <AddIcon />}
            onClick={saveChanges}
            disabled={loading}
          >
            {`${documentId ? 'Save' : 'Add'} Page`}
          </Button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default CreateEditPage; 