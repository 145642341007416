import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

function EditHeader({ title, navigateUrl }) {
    const navigate = useNavigate();
    return (
        <div className="editHeader">
            <p>{title}</p>
            <Button
                sx={{
                    backgroundColor: '#aaaaaa', '&:hover': {
                        backgroundColor: '#837a7a',
                    }, color: '#ffffff', padding: '8px 20px'
                }}
                startIcon={<KeyboardBackspaceIcon />}
                onClick={navigateUrl ? () => { navigate(navigateUrl) } : () => { navigate(-1) }}
            >
                Back
            </Button>
        </div>
    );
}
export default EditHeader;