import React from "react";
import UserDropdown from "../UserDropDown";
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from "@mui/material";

function Header({ handleDrawerToggle }) {
  return (
    <div className="userSection">
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { sm: 'none' } }}
      >
        <MenuIcon />
      </IconButton>
      <UserDropdown />
    </div>
  );
}

export default Header;