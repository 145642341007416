import React, { useEffect, useState } from 'react';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function EditorComponent({ title, onChange, value }) {
    const [initialValueSet, setInitialValueSet] = useState(false);
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(ContentState.createFromText(''))
    );

    useEffect(() => {
        if (value && !initialValueSet) {
            setEditorState(EditorState.createWithContent(htmlToDraft(value)));
            setInitialValueSet(true);
        }
    }, [value]);

    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState);
        const contentState = newEditorState.getCurrentContent();
        const htmlContent = draftToHtml(convertToRaw(contentState));
        onChange(htmlContent);
    };

    const htmlToDraft = (content) => {
        const blocksFromHTML = convertFromHTML(content);
        const contentState = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        );
        return contentState;
    }

    useEffect(() => {
        if (value) {
            htmlToDraft(value);
        }
    }, [value]);

    const toolbarOptions = {
        options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'embedded', 'colorPicker', 'image', 'emoji', 'history'],
      };

    return (
        <div className='editorSection'>
            <p>{title}</p>
            <Editor
                editorState={editorState}
                wrapperClassName="wrapperClassName"
                toolbar={toolbarOptions}
                toolbarClassName="toolbarClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
            />
        </div>
    );

}

export default EditorComponent;
