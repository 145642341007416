import React, { useEffect, useState } from 'react';
import TableComponent from "../../components/Table";
import { useFetchGrievanceMessagesQuery, useDeleteMessageMutation } from '../../features/api/messageApi';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const headers = [
    { title: "#", key: "id", type: "id" },
    { title: "NAME", key: "name", type: "text" },
    { title: "EMAIL", key: "email", type: "text" },
    { title: "MOBILE", key: "mobile", type: "text" },
    { title: "SUBJECT", key: "subject", type: "text" },
    { title: "DATE", key: "created_at", type: "date" },
];

function Grievances() {
    const navigate = useNavigate();
    const [params, setParams] = useState({ page: 1, perPage: 10 });
    const { data, refetch } = useFetchGrievanceMessagesQuery(params);
    const userData = useSelector(state => state?.auth?.user);

    useEffect(() => {
        refetch();
    }, [refetch, params]);

    useEffect(() => {
        if (!userData?.roleData['messages_viewAll']) {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData?.roleData]);

    const [deleteMessage] = useDeleteMessageMutation();

    const handleView = (id) => {
        navigate(`/messages/view/${id}`);
    }

    const handleDelete = async (id) => {
        const response = await deleteMessage(id);
        refetch();
        if (response?.data?.status) {
            toast.success(response?.data?.message, {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
            });
        }
    }

    const onSearch = (value) => {
        setParams({ ...params, search: value, page: 1 })
    }

    const onPaginationCountChange = (value) => {
        setParams({ ...params, perPage: value })
    }

    const onPaginationChange = (value) => {
        setParams({ ...params, page: value })
    }

    return (
        <div className="container">
            <div className="table-listing">
                <TableComponent
                    title="Grievances List"
                    defaultTitle={true}
                    headers={headers}
                    data={data?.data?.list}
                    metaData={data?.data?.meta}
                    rows={10}
                    buttonContent=""
                    view={false}
                    add={false}
                    buttonStatus={false}
                    actions={[{ type: 'view', role: 'viewAll', onClick: handleView }, { type: 'delete', role: 'delete', onClick: handleDelete }]}
                    permissionPath="messages"
                    currentPage={params?.page}
                    onPaginationChange={onPaginationChange}
                    onSearch={onSearch}
                    onPaginationCountChange={onPaginationCountChange}
                />
            </div>
            <ToastContainer />
        </div>
    );

}

export default Grievances;