// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.youtube_saveYoutube__wL4Cn {
  display: flex;
  padding-bottom: 30px;
}

.youtube_YoutubeDetails__a1ywK {
  padding: 15px 0;
}
.youtube_YoutubeDetails__a1ywK .youtube_YoutubeData__edc2e {
  padding-bottom: 15px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
.youtube_YoutubeDetails__a1ywK .youtube_ImageUpload__lVzU6 {
  border: 1px solid #dbdade;
  padding: 20px;
  border-radius: 5px;
  min-height: 350px;
}
@media screen and (max-width: 600px) {
  .youtube_YoutubeDetails__a1ywK .youtube_YoutubeData__edc2e {
    padding-bottom: 15px;
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/youtube/youtube.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,oBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;AACI;EACI,oBAAA;EACA,aAAA;EACA,qCAAA;EACA,SAAA;AACR;AAEI;EACI,yBAAA;EACA,aAAA;EACA,kBAAA;EACA,iBAAA;AAAR;AAGI;EACI;IACI,oBAAA;IACA,cAAA;EADV;AACF","sourcesContent":[".saveYoutube {\n    display: flex;\n    padding-bottom: 30px;\n}\n\n.YoutubeDetails {\n    padding: 15px 0;\n\n    .YoutubeData {\n        padding-bottom: 15px;\n        display: grid;\n        grid-template-columns: repeat(2, 1fr);\n        gap: 30px;\n    }\n\n    .ImageUpload {\n        border: 1px solid #dbdade;\n        padding: 20px;\n        border-radius: 5px;\n        min-height: 350px;\n    }\n\n    @media screen and (max-width: 600px) {\n        .YoutubeData {\n            padding-bottom: 15px;\n            display: block;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"saveYoutube": `youtube_saveYoutube__wL4Cn`,
	"YoutubeDetails": `youtube_YoutubeDetails__a1ywK`,
	"YoutubeData": `youtube_YoutubeData__edc2e`,
	"ImageUpload": `youtube_ImageUpload__lVzU6`
};
export default ___CSS_LOADER_EXPORT___;
