import React, { useEffect, useState } from "react";
import TableComponent from "../../components/Table";
import { useFetchTopNewsQuery, useDeleteNewsMutation } from '../../features/api/newsApi';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { localAppFEUrl, demoAppFEUrl, liveAppFEUrl } from '../../config';

const currentURL = window.location.href;
const appFEUrl = currentURL.includes('localhost') ? localAppFEUrl : currentURL.includes('glidix') ? demoAppFEUrl : liveAppFEUrl;

const headers = [
  { title: "#", key: "id", type: "id" },
  { title: "POST", key: "post", type: "imageText" },
  { title: "CATEGORY", key: "name", type: "category" },
  { title: "AUTHOR", key: "name", type: "parent", parent: "author" },
  { title: "VIEWS", key: "view", type: "text" },
  { title: "DATE ADDED", key: "date_time", type: "date" },
];

function TopNews() {
  const navigate = useNavigate();
  const [filterValues, setFilterValues] = useState({});
  const [params, setParams] = useState({ page: 1, perPage: 10 });
  const { data, refetch } = useFetchTopNewsQuery(params);
  const userData = useSelector(state => state?.auth?.user);

  useEffect(() => {
    refetch();
  }, [refetch, params]);

  useEffect(() => {
    if (!(userData?.roleData['news_viewAll'] || userData?.roleData['news_viewOwn'])) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.roleData]);

  const [deleteNews] = useDeleteNewsMutation();

  const handleEdit = (id) => {
    navigate(`/addNews/${id}`);
  }

  const handleView = (category, subcategory, slug) => {
    window.open(`${appFEUrl}news/${category}/${subcategory}/${slug}`, '_blank');
  }

  const handleDelete = async (id) => {
    const response = await deleteNews(id);
    refetch();
    if (response?.data?.status) {
      toast.success(response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
    }
  }

  const onSearch = (value) => {
    const updatedParams = { ...params };
    delete updatedParams.filter;
    setParams({ ...updatedParams, search: value, page: 1 })
  }

  const filterSearch = () => {
    setParams({ ...params, filter: encodeURIComponent(JSON.stringify(filterValues)), page: 1 })
  }

  const onPaginationCountChange = (value) => {
    setParams({ ...params, perPage: value })
  }

  const onPaginationChange = (value) => {
    setParams({ ...params, page: value })
  }

  return (
    <div className="container">
      <div className="table-listing">
        <TableComponent
          title="Top News Listing"
          defaultTitle={true}
          headers={headers}
          data={data?.data?.list}
          metaData={data?.data?.meta}
          rows={10}
          buttonContent="Add Posts"
          view={false}
          add={true}
          addUrl='/addNews'
          navigateFromBase={true}
          buttonStatus={true}
          actions={[{ type: 'viewNews', role: 'viewAll', onClick: handleView }, { type: 'edit', role: 'edit', onClick: handleEdit }, { type: 'delete', role: 'delete', onClick: handleDelete }]}
          permissionPath="news"
          currentPage={params?.page}
          onPaginationChange={onPaginationChange}
          onSearch={onSearch}
          onPaginationCountChange={onPaginationCountChange}
          filter={true}
          filterData={[
            { title: "Author", type: "author", field: "author" },
            { title: "Category", type: "category", field: "category" },
            { title: "Sub Category", type: "subcategory", field: "subcategory" },
            { title: "Date", type: 'date', field: "date" },
          ]}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          filterSearch={filterSearch}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default TopNews;
