import React, { useEffect, useState } from 'react';
import TableComponent from "../components/Table";
import { useFetchCommentQuery, useDeleteCommentMutation } from '../features/api/commentApi';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const headers = [
  { title: "#", key: "id", type: "id" },
  { title: "NAME", key: "name", type: "text" },
  { title: "EMAIL", key: "email", type: "text" },
  { title: "COMMENT", key: "comment", type: "comment" },
  { title: "DATE", key: "created_at", type: "date" },
  { title: "NEWS", key: "title", type: "parent", parent: "news" },
];

function Comments() {
  const navigate = useNavigate();
  const [params, setParams] = useState({ page: 1, perPage: 10 });
  const { data, refetch } = useFetchCommentQuery(params);
  const userData = useSelector(state => state?.auth?.user);

  useEffect(() => {
    refetch();
  }, [refetch, params]);

  useEffect(() => {
    if (!userData?.roleData['comments_viewAll']) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.roleData]);

  const [deleteComment] = useDeleteCommentMutation();

  const handleDelete = async (id) => {
    const response = await deleteComment(id);
    refetch();
    if (response?.data?.status) {
      toast.success(response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
    }
  }

  const onSearch = (value) => {
    setParams({ ...params, search: value, page: 1 })
  }

  const onPaginationCountChange = (value) => {
    setParams({ ...params, perPage: value })
  }

  const onPaginationChange = (value) => {
    setParams({ ...params, page: value })
  }

  return (
    <div className="container">
      <div className="table-listing">
        <TableComponent
          title="Comments"
          defaultTitle={true}
          headers={headers}
          data={data?.data?.list}
          metaData={data?.data?.meta}
          rows={10}
          buttonContent=""
          view={false}
          add={false}
          buttonStatus={false}
          actions={[{ type: 'delete', role: 'delete', onClick: handleDelete }]}
          permissionPath="comments"
          currentPage={params?.page}
          onPaginationChange={onPaginationChange}
          onSearch={onSearch}
          onPaginationCountChange={onPaginationCountChange}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default Comments;