// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.games_saveGame__46PpZ {
  display: flex;
  padding: 15px 0;
}

.games_gameDetails__iJQM2 {
  padding: 15px 0;
}
.games_gameDetails__iJQM2 .games_gameData__HXEu5 {
  padding: 15px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
.games_gameDetails__iJQM2 .games_ImageUpload__HFY2L {
  border: 1px solid #dbdade;
  padding: 20px;
  border-radius: 5px;
  min-height: 300px;
}

@media only screen and (max-width: 600px) {
  .games_gameDetails__iJQM2 .games_gameData__HXEu5 {
    display: block;
  }
  .games_saveGame__46PpZ {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/games/games.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;AACJ;;AAEA;EACI,eAAA;AACJ;AACI;EACI,aAAA;EACA,aAAA;EACA,qCAAA;EACA,SAAA;AACR;AAEI;EACI,yBAAA;EACA,aAAA;EACA,kBAAA;EACA,iBAAA;AAAR;;AAIA;EAEQ;IACI,cAAA;EAFV;EAKE;IACI,cAAA;EAHN;AACF","sourcesContent":[".saveGame {\n    display: flex;\n    padding: 15px 0;\n}\n\n.gameDetails {\n    padding: 15px 0;\n\n    .gameData {\n        padding: 15px;\n        display: grid;\n        grid-template-columns: repeat(2, 1fr);\n        gap: 30px;\n    }\n\n    .ImageUpload {\n        border: 1px solid #dbdade;\n        padding: 20px;\n        border-radius: 5px;\n        min-height: 300px;\n    }\n}\n\n@media only screen and (max-width: 600px) {\n    .gameDetails {\n        .gameData {\n            display: block;\n        }\n    }\n    .saveGame{\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"saveGame": `games_saveGame__46PpZ`,
	"gameDetails": `games_gameDetails__iJQM2`,
	"gameData": `games_gameData__HXEu5`,
	"ImageUpload": `games_ImageUpload__HFY2L`
};
export default ___CSS_LOADER_EXPORT___;
