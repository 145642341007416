import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from '@mui/icons-material/Save';
import CustomTextField from "../../components/CustomTextField";
import CheckboxLabels from "../../components/CheckBox"
import { ROLES, OWN_ROLES, CAPABILITIES } from "../../utils";
import EditHeader from "../../components/EditHeader";
import Styles from "./roles.module.scss";
import { FormGroup } from "@mui/material";
import { useCreateRoleMutation, useFetchRoleDetailQuery, useUpdateRoleMutation } from '../../features/api/roleApi';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { isEmpty, validateData } from "../../helper";
import { useSelector } from "react-redux";

function CreateEditRole() {
  const navigate = useNavigate();
  let { id: documentId } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const userData = useSelector(state => state?.auth?.user);

  const { data: roleData, refetch } = useFetchRoleDetailQuery(documentId);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (!documentId && !userData?.roleData['roles_create']) {
      navigate('/');
    }
    if (documentId && !userData?.roleData['roles_edit']) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [userData?.roleData]);

  useEffect(() => {
    setData({ ...data, ...roleData?.data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleData]);

  const [createRole] = useCreateRoleMutation();
  const [updateRole] = useUpdateRoleMutation();

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onCheckBoxChange = (e, role) => {
    setData(prevData => {
      let updatedData = { ...prevData, [`${role}_${e.target.name}`]: e.target.checked };

      if (['news', 'schedulePost', 'drafts', 'career', 'articles'].includes(role)) {
        if (e.target.name === 'viewAll' && e.target.checked) updatedData[`${role}_viewOwn`] = false;
        if (e.target.name === 'viewOwn' && e.target.checked) updatedData[`${role}_viewAll`] = false;
        if (!(updatedData[`${role}_viewOwn`] || updatedData[`${role}_viewAll`])) {
          updatedData[`${role}_create`] = false;
          updatedData[`${role}_edit`] = false;
          updatedData[`${role}_delete`] = false;
        }
      } else {
        if (!updatedData[`${role}_viewAll`]) {
          updatedData[`${role}_create`] = false;
          updatedData[`${role}_edit`] = false;
          updatedData[`${role}_delete`] = false;
        }
      }
  
      return updatedData;
    });
  };

  const saveChanges = async () => {
    setLoading(true);
    const errors = validateData(data, ['name']);
    if (!isEmpty(errors)) {
      setLoading(false);
      toast.error(errors, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
      return true;
    }

    let response = {};
    if (documentId) {
      response = await updateRole(data);
    } else {
      response = await createRole(data);
    }
    if (response?.data?.status) {
      setLoading(false);
      toast.success(response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
      });
      setTimeout(() => {
        navigate('/roles')
      }, 2000);
    } else {
      setLoading(false);
      toast.error(response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
    }
  };

  return (
    <div className="container">
      <EditHeader title="Add Role" navigateUrl="/roles" />
      <div className={Styles.roleContent}>
        <CustomTextField fullWidth name='name' required value={data?.name} onChange={onChange} label='Role Name' placeholder='Role Name' />
      </div>

      <div className={Styles.roleDetails}>
        <table>
          <thead>
            <tr>
              <th>Features</th>
              <th>Capabilities</th>
            </tr>
          </thead>
          <tbody>
            {ROLES.map((role) => (
              <tr key={role.value}>
                <td>{role.label}</td>
                <td>
                  <FormGroup row={false}>
                    {CAPABILITIES?.map((item, index) => (
                      <>
                        {item.value === "viewOwn" ?
                          <>
                            {OWN_ROLES.includes(role.value) &&
                              <CheckboxLabels key={index} onChange={(e) => onCheckBoxChange(e, role.value)} value={data[`${role.value}_${item.value}`]} item={item} />
                            }
                          </> :
                          <CheckboxLabels 
                            key={index} 
                            onChange={(e) => onCheckBoxChange(e, role.value)} 
                            value={data[`${role.value}_${item.value}`]} 
                            item={item}
                            disabled={
                              ['news', 'schedulePost', 'drafts', 'career', 'articles'].includes(role.value) ? 
                              !item.value.includes('view') && (!data[`${role.value}_viewOwn`] && !data[`${role.value}_viewAll`]) :
                              !item.value.includes('view') && !data[`${role.value}_viewAll`]
                            }
                          />
                        }
                      </>
                    ))}
                  </FormGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className={Styles.saveRoles}>
        <Button
          sx={{
            backgroundColor: '#7367F0', '&:hover': {
              backgroundColor: '#6056CD',
            }, color: '#ffffff', padding: '8px 20px',
            marginLeft: 'auto'
          }}
          startIcon={documentId ?<SaveIcon />:<AddIcon />}
          onClick={() => saveChanges()}
          disabled={loading}
        >
          {`${documentId ? 'Update' : 'Add'} Role`}
        </Button>
      </div>
      <ToastContainer />
    </div>
  );
}

export default CreateEditRole;
