import React, { useState } from 'react';
import CustomTextField from '../CustomTextField';
import TagBox from '../Tag';

function TabPanel(props) {
    const { data, setData, children, keyword, value, index, ...other } = props;
    const [tagValue, setTagValue] = useState('');

    const onChange = (e) => {
        const updatedData = data[keyword].map(item => {
            if (item[e.target.name] !== undefined) {
                return { ...item, [e.target.name]: e.target.value };
            }
            return item;
        });
        setData({ ...data, [keyword]: updatedData });
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            const updatedData = data[keyword].map(item => {
                if (item['meta_tags'] !== undefined && !item.meta_tags.includes(tagValue)) {
                    return { ...item, meta_tags: [...item.meta_tags, tagValue] };
                }
                return item;
            });
            setData({ ...data, [keyword]: updatedData });
            setTagValue('');
        }
    };

    const handleTagCancel = (tagToRemove) => {
        const updatedData = data[keyword].map(item => {
            if (item['meta_tags'] !== undefined) {
                return { ...item, meta_tags: item?.meta_tags.filter((item) => item !== tagToRemove) };
            }
            return item;
        });
        setData({ ...data, [keyword]: updatedData });
    };

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            {...other}
        >
            <div className='settings'>
                {data[keyword].length && data[keyword]?.map((item, index) => {
                    const key = Object.keys(item)[0];
                    return (
                        <div key={index} className='settingsItems'>
                            <p className='settingsTitle'>{key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</p>
                            <div className='settingsValue'>
                                {Array.isArray(item[key]) ? <>
                                    <CustomTextField
                                        fullWidth
                                        name={key}
                                        value={tagValue}
                                        onChange={(e) => setTagValue(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                        placeholder='Add a tag'
                                        helperText='(Type tag and hit enter)'
                                    />
                                    {item[key] && <div className="tagOuter">
                                        {item[key]?.map((tag, index) => (
                                            <TagBox key={index} tag={tag} handleTagCancel={handleTagCancel} />
                                        ))}
                                    </div>}
                                </> :
                                    <CustomTextField fullWidth name={key} value={item[key]} onChange={onChange} placeholder={key} />
                                }
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default TabPanel;