// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation_navDetails__q3Wme {
  padding: 15px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.navigation_visibility__Y88vi {
  padding: 15px 0;
}

.navigation_buttons__pZQwg {
  display: flex;
  padding-bottom: 30px;
}

.navigation_appendBtn__tD-Af {
  padding: 0.55rem;
  border: 1px solid #c5c4c9;
  border-radius: 0.3rem;
}

.navigation_appendBtnDiv__DxlKg {
  margin-top: 1.43rem;
}

@media screen and (max-width: 600px) {
  .navigation_navDetails__q3Wme {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/navigation/navigation.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,aAAA;EACA,qCAAA;EACA,SAAA;AACJ;;AACA;EACI,eAAA;AAEJ;;AAAA;EACI,aAAA;EACA,oBAAA;AAGJ;;AADA;EACI,gBAAA;EACA,yBAAA;EACA,qBAAA;AAIJ;;AAFA;EACI,mBAAA;AAKJ;;AAHA;EACI;IACI,cAAA;EAMN;AACF","sourcesContent":[".navDetails {\n    padding: 15px 0;\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 15px;\n}\n.visibility {\n    padding: 15px 0;\n}\n.buttons {\n    display: flex;\n    padding-bottom: 30px;\n}\n.appendBtn {\n    padding: .55rem;\n    border: 1px solid #c5c4c9;\n    border-radius: .3rem;\n}\n.appendBtnDiv {\n    margin-top: 1.43rem;\n}\n@media screen and (max-width: 600px) {\n    .navDetails {\n        display: block;\n    }\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navDetails": `navigation_navDetails__q3Wme`,
	"visibility": `navigation_visibility__Y88vi`,
	"buttons": `navigation_buttons__pZQwg`,
	"appendBtn": `navigation_appendBtn__tD-Af`,
	"appendBtnDiv": `navigation_appendBtnDiv__DxlKg`
};
export default ___CSS_LOADER_EXPORT___;
