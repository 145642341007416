import React, { useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Menu, Badge, Avatar, Divider, Typography, MenuItem } from '@mui/material';
import Person2Icon from '@mui/icons-material/Person2';
import LogoutIcon from '@mui/icons-material/Logout';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { clearUser } from '../../features/slice/authSlice';
import { logoutUser } from '../../features/api/authApi';
import { imageBaseUrl, imageLiveBaseUrl } from "../../config";

const currentURL = window.location.href;
const imageUrl = currentURL.includes('nattuvartha') ? imageLiveBaseUrl : imageBaseUrl;

const BadgeContentSpan = styled('span')(({ theme }) => ({
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: theme.palette.success.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}))

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
    '&:hover .MuiBox-root, &:hover .MuiBox-root svg': {
        color: theme.palette.primary.main
    }
}))

const UserDropdown = props => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const userData = useSelector(state => state?.auth?.user);

    const handleDropdownOpen = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleDropdownClose = url => {
        if (url) {
            navigate(url)
        }
        setAnchorEl(null)
    }

    const styles = {
        px: 4,
        py: 1.75,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        color: 'text.primary',
        textDecoration: 'none',
        '& svg': {
            mr: 2.5,
            fontSize: '1.5rem',
            color: 'text.secondary'
        }
    }

    const handleLogout = async () => {
        const response = await logoutUser();
        if (response?.status) {
            dispatch(clearUser());
            navigate('/login');
        }
        handleDropdownClose();
    }

    return (
        <div className="headerLastItem">
            <Fragment>
                <Badge
                    overlap='circular'
                    onClick={handleDropdownOpen}
                    sx={{ ml: 2, cursor: 'pointer' }}
                    badgeContent={<BadgeContentSpan />}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                >
                    <Avatar
                        alt={userData?.name}
                        src={userData?.image ? `${imageUrl}${userData?.image}` : '/avatar.png'}
                        onClick={handleDropdownOpen}
                        sx={{ width: 38, height: 38 }}
                    />
                </Badge>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => handleDropdownClose()}
                    sx={{ '& .MuiMenu-paper': { width: 250, mt: 4.75 } }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    <Box sx={{ py: 1.75, px: 6 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Badge
                                overlap='circular'
                                badgeContent={<BadgeContentSpan />}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                            >
                                <Avatar alt={userData?.name} src={userData?.image ? `${imageUrl}${userData?.image}` : '/avatar.png'} sx={{ width: '2.5rem', height: '2.5rem' }} />
                            </Badge>
                            <Box sx={{ display: 'flex', ml: 2.5, alignItems: 'flex-start', flexDirection: 'column' }}>
                                <Typography sx={{
                                    fontWeight: 500,
                                    color: 'rgba(47, 43, 61, 0.68)',
                                    fontFamily: 'Public Sans',
                                    fontSize: '15px',
                                    fontStyle: 'normal',
                                    lineHeight: '15px'
                                }}>{userData?.name}</Typography>
                                <Typography sx={{
                                    color: 'rgba(47, 43, 61, 0.68)',
                                    fontFamily: 'Public Sans',
                                    fontSize: '15px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '15px'
                                }} variant='body2'>{userData?.role?.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (str) => str.toUpperCase())}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Divider sx={{ my: theme => `${theme.spacing(2)} !important` }} />
                    <MenuItemStyled sx={{ p: 0 }} onClick={() => handleDropdownClose('/profile')}>
                        <Box sx={styles}>
                            <Person2Icon />
                            <p className='userMenuItems'>My Profile</p>
                        </Box>
                    </MenuItemStyled>
                    <Divider sx={{ my: theme => `${theme.spacing(2)} !important` }} />
                    <MenuItemStyled sx={{ p: 0 }} onClick={handleLogout}>
                        <Box sx={styles}>
                            <LogoutIcon />
                            <p className='userMenuItems'>Sign Out</p>
                        </Box>
                    </MenuItemStyled>
                </Menu>
            </Fragment>
        </div>
    )
}

export default UserDropdown
