import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setAuthHeader } from './apiHelpers';
import { liveAppUrl, demoAppUrl, localAppUrl } from '../../config';

const currentURL = window.location.href;
const appURL = currentURL.includes('localhost') ? localAppUrl : currentURL.includes('glidix') ? demoAppUrl : liveAppUrl;

export const messageApi = createApi({
    reducerPath: 'messageApi',
    baseQuery: fetchBaseQuery({
        baseUrl: appURL,
        prepareHeaders: (headers) => {
            setAuthHeader(headers);
            return headers;
        },
    }),
    endpoints: (builder) => ({
        fetchContactMessages: builder.query({
            query: (params) => {
                if (params?.limit) {
                    return `/message/contact-list?limit=${params?.limit}`;
                } else if (params?.search) {
                    return `/message/contact-list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}&search=${params.search}`;
                  } else {
                    return `/message/contact-list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}`;
                  }
            }
        }),
        fetchGrievanceMessages: builder.query({
            query: (params) => {
                if (params?.limit) {
                    return `/message/grievance-list?limit=${params?.limit}`;
                } else if (params?.search) {
                    return `/message/grievance-list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}&search=${params.search}`;
                  } else {
                    return `/message/grievance-list?page=${params?.page ? params?.page : 1}&perPage=${params?.perPage ? params?.perPage : 10}`;
                  }
            }
        }),
        fetchMessageDetail: builder.query({
            query: (id) => `/message/detail/${id}`,
        }),
        deleteMessage: builder.mutation({
            query: (id) => ({
                url: `/message/delete/${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useFetchContactMessagesQuery,
    useFetchGrievanceMessagesQuery,
    useFetchMessageDetailQuery,
    useDeleteMessageMutation
} = messageApi;
