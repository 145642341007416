import { Box, IconButton, List, ListItem, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useDropzone } from "react-dropzone";
import CancelIcon from "../../assets/icons/cancel";
import UploadIcon from "../../assets/icons/upload";
import Styles from "./FileUploader.module.scss";
import { imageBaseUrl, imageLiveBaseUrl } from "../../config";

const currentURL = window.location.href;
const imageUrl = currentURL.includes('nattuvartha') ? imageLiveBaseUrl : imageBaseUrl;

function FileUploader({ name, onFileDrop, images, fileCount, setData, data }) {
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (fileCount && acceptedFiles.length > fileCount) {
        acceptedFiles = acceptedFiles.slice(0, fileCount);
      }
      setFiles(acceptedFiles.map((file) => Object.assign(file)));
      onFileDrop(name, acceptedFiles.map((file) => Object.assign(file)));
      delete data[name];
      setData({ ...data });
    },
  });

  const renderFilePreview = (file) => {
    if (file.type.startsWith("image")) {
      return (
        <img
          alt={file.name}
          src={URL.createObjectURL(file)}
        />
      );
    }
  };

  const handleRemoveFile = (file) => {
    const uploadedFiles = files;
    const filtered = uploadedFiles.filter((i) => i.name !== file.name);
    setFiles([...filtered]);
    onFileDrop(name, [...filtered]);
  };

  const fileList = files.map((file) => (
    <ListItem key={file.name} sx={{ width: 'auto' }}>
      <div className={Styles.fileDetails}>
        <div className={Styles.filePreview}>{renderFilePreview(file)}</div>
        <IconButton
          className={Styles.closeButton}
          onClick={() => handleRemoveFile(file)}
        >
          <CancelIcon />
        </IconButton>
      </div>
    </ListItem>
  ));

  const handleRemoveImage = (image) => {
    if (typeof data[name] === 'string') {
      delete data[name];
      setData({ ...data });
    } else {
      setData({ ...data, [name]: data[name].filter((value) => value !== image) })
    }
  }

  const imageItem = (image, index) => {
    return (
      <div key={index} className={Styles.fileDetails}>
        <div className={Styles.filePreview}>
          <img src={`${imageUrl}${image}`} alt={index} />
        </div>
        <IconButton
          className={Styles.closeButton}
          onClick={() => handleRemoveImage(image)}
        >
          <CancelIcon />
        </IconButton>
      </div>
    );
  }

  return (
    <Fragment>
      <div className={Styles.fileUploader}>
        <div className={Styles.uploadSection}>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <Box
              sx={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  display: "flex",
                  borderRadius: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "rgba(47, 43, 61, 0.08)",
                }}
              >
                <UploadIcon />
              </Box>
              <Typography sx={{ fontSize: "15px", marginTop: "20px" }}>
                Drop files here or click to edit thumbnail.
              </Typography>
            </Box>
          </div>
        </div>
        {files.length ? (
          <Fragment>
            <List sx={{
              display: "flex",
              justifyContent: "center",
              color: 'rgba(47, 43, 61, 0.68)',
              fontFamily: 'Public Sans',
              fontSize: '15px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '15px'
            }}>
              {fileList}
            </List>
          </Fragment>
        ) : null}
        {images &&
          <div className={Styles.imageListing}>
            {typeof images === "string" ?
              imageItem(images) :
              <>
                {images.map((image, index) => { return imageItem(image, index) })}
              </>
            }
          </div>
        }
      </div>
    </Fragment>
  );
}

export default FileUploader;
