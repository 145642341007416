import React, { useEffect, useState } from "react";
import TableComponent from "../../components/Table";
import { useFetchYoutubeQuery, useDeleteYoutubeMutation } from '../../features/api/youtubeApi';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";

const headers = [
  { title: "#", key: "id", type: "id" },
  { title: "TITLE", key: "title", type: "text" },
  { title: "VIDEO URL", key: "url", type: "text" },
  { title: "THUMBNAIL", key: "image", type: "image" },
];

function Youtube() {
  const navigate = useNavigate();
  const [params, setParams] = useState({ page: 1, perPage: 10 });
  const { data, refetch } = useFetchYoutubeQuery(params);
  const userData = useSelector(state => state?.auth?.user);

  useEffect(() => {
    refetch();
  }, [refetch, params]);

  useEffect(() => {
    if (!userData?.roleData['youtube_viewAll']) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.roleData]);

  const [deleteYoutube] = useDeleteYoutubeMutation();

  const handleEdit = (id) => {
    navigate(`/youtube/edit/${id}`);
  }
  const handleDelete = async (id) => {
    const response = await deleteYoutube(id);
    refetch();
    if (response?.data?.status) {
      toast.success(response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
    }
  }

  const onSearch = (value) => {
    setParams({ ...params, search: value, page: 1 })
  }

  const onPaginationCountChange = (value) => {
    setParams({ ...params, perPage: value })
  }

  const onPaginationChange = (value) => {
    setParams({ ...params, page: value })
  }

  return (
    <div className="container">
      <div className="table-listing">
        <TableComponent
          title="Youtube"
          defaultTitle={true}
          headers={headers}
          data={data?.data?.list}
          metaData={data?.data?.meta}
          rows={10}
          buttonContent="New"
          view={false}
          add={true}
          buttonStatus={true}
          actions={[{ type: 'edit', role: 'edit', onClick: handleEdit }, { type: 'delete', role: 'delete', onClick: handleDelete }]}
          permissionPath="youtube"
          currentPage={params?.page}
          onPaginationChange={onPaginationChange}
          onSearch={onSearch}
          onPaginationCountChange={onPaginationCountChange}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default Youtube;
