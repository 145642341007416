import React from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import Styles from "./TagBox.module.scss";

function TagBox({ tag, handleTagCancel }) {
    return (
        <div onClick={() => handleTagCancel(tag)} className={Styles.tagItem}>
            {tag}
            <span><CancelIcon /></span>
        </div>
    );
}
export default TagBox;