// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings_settingsOuter__tWCgr {
  position: relative;
}
.settings_settingsOuter__tWCgr .settings_saveSettings__ZWmOB {
  display: flex;
  position: absolute;
  bottom: 0;
  padding-bottom: 30px;
  right: 30px;
}
@media screen and (max-width: 600px) {
  .settings_settingsOuter__tWCgr .settings_saveSettings__ZWmOB {
    width: 100%;
    display: block;
    position: static;
  }
  .settings_settingsOuter__tWCgr .settings_saveSettings__ZWmOB button {
    margin-left: auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Settings/settings.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AACI;EACI,aAAA;EACA,kBAAA;EACA,SAAA;EACA,oBAAA;EACA,WAAA;AACR;AAEI;EACI;IAEI,WAAA;IACA,cAAA;IACA,gBAAA;EADV;EAGU;IACI,iBAAA;EADd;AACF","sourcesContent":[".settingsOuter {\n    position: relative;\n\n    .saveSettings {\n        display: flex;\n        position: absolute;\n        bottom: 0;\n        padding-bottom: 30px;\n        right: 30px;\n    }\n\n    @media screen and (max-width: 600px) {\n        .saveSettings {\n            // padding-bottom: 15px;\n            width: 100%;\n            display: block;\n            position: static;\n\n            button {\n                margin-left: auto;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingsOuter": `settings_settingsOuter__tWCgr`,
	"saveSettings": `settings_saveSettings__ZWmOB`
};
export default ___CSS_LOADER_EXPORT___;
