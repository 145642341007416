import React from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Styles from './pagination.module.scss'
import { IconButton } from '@mui/material';

function Pagination({ page, data, onPaginationChange }) {
    const { last_page, from = 1, to = 10, total = 30 } = data;
    
    return (
        <div className={Styles.paginationOuter}>
            <div className={Styles.spacer}></div>
            <p className={Styles.displayedRows}>{`${from || 0}–${to || 0} of ${total || 0}`}</p>
            <div className={Styles.actions}>
                <IconButton color="inherit" disabled={page === 1} onClick={() => {onPaginationChange(page - 1)}}><KeyboardArrowLeftIcon /></IconButton>
                <IconButton color="inherit" disabled={last_page === page} onClick={() => {onPaginationChange(page + 1)}}><KeyboardArrowRightIcon /></IconButton>
            </div>
        </div>
    );
}

export default Pagination;