import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import SelectItem from "../Select";
import DateTimePickerItem from "../DatePicker";
import Styles from "./Filter.module.scss";

function Filter({ filterData, filterValues, setFilterValues, filterSearch, adSpaces, authors, categories }) {
  const mainCategories = categories?.filter((item) => !item.parent_id);
  const subCategories = categories?.filter((item) => item.parent_id && item.parent_id === filterValues.category);

  const onChange = (e) => {
    if (e.target.name === 'category') setFilterValues({ ...filterValues, [e.target.name]: e.target.value, subcategory: null });
    else setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
  };

  const onDateChange = (name, date) => {
    setFilterValues({ ...filterValues, [name]: date });
  };

  return (
    <div className={Styles.filterSection}>
      {filterData?.map((filters) => {
        switch (filters.type) {
          case "date":
            return (
              <div className={Styles.filterDate}>
                <DateTimePickerItem key={filters.field} name={filters.field} value={filterValues?.[filters.field]} onChange={onDateChange} label="Date" />
              </div>
            );
          case "author":
            return (
              <div key={filters.field} className={Styles.filterItem}>
                <SelectItem
                  title={filters.title}
                  name={filters.field}
                  value={filterValues?.[filters.field] ? authors?.filter((item) => item._id === filterValues?.[filters.field])[0]?.name : ''}
                  selector='_id'
                  items={authors?.slice().sort((a, b) => a.name.localeCompare(b.name))}
                  placeHolder={`Select a ${filters.title}`}
                  onChange={onChange}
                />
              </div>
            );
          case "ad_space":
            return (
              <div key={filters.field} className={Styles.filterItem}>
                <SelectItem
                  title={filters.title}
                  name={filters.field}
                  value={filterValues?.[filters.field] ? adSpaces?.filter((item) => item.value === filterValues?.[filters.field])[0]?.label : ''}
                  selector='value'
                  items={adSpaces}
                  placeHolder={`Select a ${filters.title}`}
                  onChange={onChange}
                />
              </div>
            );
          case "subcategory":
            return (
              <div key={filters.field} className={Styles.filterItem}>
                <SelectItem
                  title={filters.title}
                  name={filters.field}
                  value={filterValues?.[filters.field] ? subCategories?.filter((item) => item._id === filterValues?.[filters.field])[0]?.name : ''}
                  selector='_id'
                  items={subCategories?.slice().sort((a, b) => a.name.localeCompare(b.name))}
                  placeHolder={`Select a ${filters.title}`}
                  onChange={onChange}
                />
              </div>
            );

          default:
            return (
              <div key={filters.field} className={Styles.filterItem}>
                <SelectItem
                  title={filters.title}
                  name={filters.field}
                  value={filterValues?.[filters.field] ? mainCategories?.filter((item) => item._id === filterValues?.[filters.field])[0]?.name : ''}
                  selector='_id'
                  items={mainCategories?.slice().sort((a, b) => a.name.localeCompare(b.name))}
                  placeHolder={`Select a ${filters.title}`}
                  onChange={onChange}
                />
              </div>
            );
        }
      })}
      <button className={Styles.filterButton} onClick={filterSearch}>
        <SearchIcon sx={{ fontSize: 20, color: "#ffffff" }} />
        <span>Filter</span>
      </button>
    </div>
  );
}

export default Filter;
