import React from "react";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Styles from "./DatePickerItem.module.scss";

function DateTimePickerItem({ name, label, value, onChange, dateTime = false, }) {
  const handleChange = (name, date) => {
    onChange(name, date.toISOString());
  }
  return (
    <div className={Styles.DateTimePicker}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoItem label={label}>
          <div className={Styles.datePickerForm}>
          {dateTime ? <DateTimePicker name={name} className={Styles.fullWidth} onChange={(date) => handleChange(name, date)} value={dayjs(value)} /> :
            <DatePicker name={name} onChange={(date) => handleChange(name, date)} className={Styles.fullWidth} value={dayjs(value)} />
          }
          </div>
        </DemoItem>
      </LocalizationProvider>
    </div>
  );
}

export default DateTimePickerItem;
