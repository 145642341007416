import React, { useEffect, useState } from "react";
import TableComponent from "../components/Table";
import { useFetchNewsCountQuery } from '../features/api/newsApi';
import { useFetchCommentQuery } from '../features/api/commentApi';
import { useFetchContactMessagesQuery, useFetchGrievanceMessagesQuery } from '../features/api/messageApi';
import Cards from "../components/Cards";

const headers1 = [
  { title: "#", key: "id", type: "id" },
  { title: "NAME", key: "name", type: "text" },
  { title: "EMAIL", key: "email", type: "text" },
  { title: "COMMENT", key: "comment", type: "comment" },
  { title: "DATE", key: "created_at", type: "date" },
];

const headers2 = [
  { title: "#", key: "id", type: "id" },
  { title: "NAME", key: "name", type: "text" },
  { title: "EMAIL", key: "email", type: "text" },
  { title: "MESSAGE", key: "message", type: "text" },
  { title: "DATE", key: "created_at", type: "date" },
];

const cardData = [
  {
    title: "Posts",
    key: "posts",
    path: "/news",
  },
  {
    title: "Scheduled Posts",
    key: "scheduled_posts",
    path: "/schedulePost",
  },
  {
    title: "Drafts",
    key: "drafts",
    path: "/drafts",
  },
];

function Dashboard() {
  const [params, setParams] = useState({
    comments: { page: 1, perPage: 5 },
    contacts: { page: 1, perPage: 5 },
    grievances: { page: 1, perPage: 5 },
  });
  const { data: countData } = useFetchNewsCountQuery();
  const { data: CommentsData, refetch: commentRefetch } = useFetchCommentQuery(params.comments);
  const { data: contactsData, refetch: contactsRefetch } = useFetchContactMessagesQuery(params.contacts);
  const { data: grievancesData, refetch: grievancesReftech } = useFetchGrievanceMessagesQuery(params.grievances);

  useEffect(() => {
    commentRefetch();
  }, [commentRefetch, params.comments]);

  useEffect(() => {
    contactsRefetch();
  }, [contactsRefetch, params.contacts]);

  useEffect(() => {
    grievancesReftech();
  }, [grievancesReftech, params.grievances]);

  const onSearch = (name, value) => {
    setParams({ ...params, [name]: { ...params[name], search: value, page: 1 } })
  }

  const onPaginationCountChange = (name, value) => {
    setParams({ ...params, [name]: { ...params[name], perPage: value } })
  }

  const onPaginationChange = (name, value) => {
    setParams({ ...params, [name]: { ...params[name], page: value } })
  }

  return (
    <div className="container">
      <div className="statistics">
        <p className="dahsboardHeader">Dashboard</p>
        <Cards data={cardData} countData={countData?.data} />
      </div>
      <div className="table-listing">
        <TableComponent
          title="Latest Comments"
          defaultTitle={false}
          headers={headers1}
          data={CommentsData?.data?.list}
          metaData={CommentsData?.data?.meta}
          rows={5}
          buttonContent="View All"
          view={true}
          viewUrl="/comments"
          add={false}
          buttonStatus={true}
          serachFields={['name', 'email', 'comment']}
          currentPage={params?.page}
          onPaginationChange={(value) => onPaginationChange('comments', value)}
          onSearch={(value) => onSearch('comments', value)}
          onPaginationCountChange={(value) => onPaginationCountChange('comments', value)}
        />
      </div>
      <div className="table-listing">
        <TableComponent
          title="Latest Contact Message"
          defaultTitle={false}
          headers={headers2}
          data={contactsData?.data?.list}
          metaData={contactsData?.data?.meta}
          rows={5}
          buttonContent="View All"
          view={true}
          viewUrl="/contacts"
          add={false}
          buttonStatus={true}
          serachFields={['name', 'email', 'message']}
          currentPage={params?.page}
          onPaginationChange={(value) => onPaginationChange('contacts', value)}
          onSearch={(value) => onSearch('contacts', value)}
          onPaginationCountChange={(value) => onPaginationCountChange('contacts', value)}
        />
      </div>
      <div className="table-listing">
        <TableComponent
          title="Latest Grievance Message"
          defaultTitle={false}
          headers={headers2}
          data={grievancesData?.data?.list}
          metaData={grievancesData?.data?.meta}
          rows={5}
          buttonContent="View All"
          view={true}
          viewUrl="/grievances"
          add={false}
          buttonStatus={true}
          serachFields={['name', 'email', 'message']}
          currentPage={params?.page}
          onPaginationChange={(value) => onPaginationChange('grievances', value)}
          onSearch={(value) => onSearch('grievances', value)}
          onPaginationCountChange={(value) => onPaginationCountChange('grievances', value)}
        />
      </div>
    </div>
  );
}

export default Dashboard;
