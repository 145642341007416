import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import authReducer from './features/slice/authSlice';
import { categoryApi } from './features/api/categoryApi';
import { newsApi } from './features/api/newsApi';
import { careerApi } from './features/api/careerApi';
import { advertisementApi } from './features/api/advertisementApi';
import { articleApi } from './features/api/articleApi';
import { pageApi } from './features/api/pageApi';
import { roleApi } from './features/api/roleApi';
import { navigationApi } from './features/api/navigationApi';
import { userApi } from './features/api/userApi';
import { commentApi } from './features/api/commentApi';
import { youtubeApi } from './features/api/youtubeApi';
import { gameApi } from './features/api/gameApi';
import { messageApi } from './features/api/messageApi';
import { settingsApi } from './features/api/settingsApi';
import thunk from 'redux-thunk';

const store = configureStore({
  reducer: {
    auth: authReducer,
    categoryApi: categoryApi.reducer,
    newsApi: newsApi.reducer,
    careerApi: careerApi.reducer,
    advertisementApi: advertisementApi.reducer,
    articleApi: articleApi.reducer,
    pageApi: pageApi.reducer,
    roleApi: roleApi.reducer,
    navigationApi: navigationApi.reducer,
    userApi: userApi.reducer,
    commentApi: commentApi.reducer,
    youtubeApi: youtubeApi.reducer,
    gameApi: gameApi.reducer,
    messageApi: messageApi.reducer,
    settingsApi: settingsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      thunk,
      categoryApi.middleware,
      newsApi.middleware,
      careerApi.middleware,
      advertisementApi.middleware,
      articleApi.middleware,
      pageApi.middleware,
      roleApi.middleware,
      navigationApi.middleware,
      userApi.middleware,
      commentApi.middleware,
      youtubeApi.middleware,
      gameApi.middleware,
      messageApi.middleware,
      settingsApi.middleware,
    ),
});

setupListeners(store.dispatch);

export default store;