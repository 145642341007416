import React, { useEffect, useState } from 'react';
import { useFetchSettingsQuery, useUpdateSettingsMutation } from '../../features/api/settingsApi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Tab, Tabs } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import TabPanel from '../../components/TabPanel';
import { ToastContainer, toast } from 'react-toastify';
import Styles from "./settings.module.scss";

function Settings() {
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        general: {},
        seo: {},
        mail: {},
    });
    const { data: SettingsData, refetch } = useFetchSettingsQuery();
    const userData = useSelector(state => state?.auth?.user);

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        setData({ ...data, ...SettingsData?.data[0] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SettingsData]);

    useEffect(() => {
        if (!userData?.roleData['settings_viewAll']) {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData?.roleData]);

    const [updateSettings] = useUpdateSettingsMutation();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const saveChanges = async () => {
        setLoading(true);
        const response = await updateSettings(data);

        if (response?.data?.status) {
            setLoading(false);
            toast.success(response?.data?.message, {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 1000,
            });
        } else {
            setLoading(false);
            toast.error(response?.data?.message, {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
            });
        }
    };

    return (
        <div className={`container ${Styles.settingsOuter}`}>
            <div className="editHeader">
                <p>Settings</p>
            </div>
            <Box sx={{ flexGrow: 1 }}>
                <Tabs value={value} indicatorColor='primary' textColor="primary" onChange={handleChange}>
                    <Tab label="General Settings" />
                    <Tab label="Seo Settings" />
                    <Tab label="Mail Settings" />
                </Tabs>
                <TabPanel value={value} index={0} setData={setData} keyword={'general'} data={data}>
                    Content for Tab 1
                </TabPanel>
                <TabPanel value={value} index={1} setData={setData} keyword={'seo'} data={data}>
                    Content for Tab 2
                </TabPanel>
                <TabPanel value={value} index={2} setData={setData} keyword={'mail'} data={data}>
                    Content for Tab 3
                </TabPanel>
            </Box>

            <div className={Styles.saveSettings}>
                <Button
                    sx={{
                        backgroundColor: '#7367F0', '&:hover': {
                            backgroundColor: '#6056CD',
                        }, color: '#ffffff', padding: '8px 20px',
                        marginLeft: 'auto'
                    }}
                    startIcon={<SaveIcon />}
                    onClick={() => saveChanges()}
                    disabled={loading}
                >
                    {'Update Settings'}
                </Button>
            </div>
            <ToastContainer />
        </div>
    );
}

export default Settings;