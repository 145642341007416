// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FileUploader_fileUploader__P4yeK .FileUploader_uploadSection__ZpFzC {
  min-height: 150px;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 6px;
  border: 2px dashed rgba(47, 43, 61, 0.16);
}
.FileUploader_fileUploader__P4yeK .FileUploader_fileDetails__GDHW3 {
  position: relative;
  border: 1px solid #dbdade;
  padding: 10px;
  border-radius: 5px;
}
.FileUploader_fileUploader__P4yeK .FileUploader_fileDetails__GDHW3 .FileUploader_filePreview__kIOyp img {
  border-radius: 5px;
  max-width: 100%;
}
.FileUploader_fileUploader__P4yeK .FileUploader_fileDetails__GDHW3 .FileUploader_closeButton__VzC8m {
  right: 10px;
  top: 10px;
  position: absolute;
}
.FileUploader_fileUploader__P4yeK .FileUploader_imageListing__jN7z3 {
  display: flex;
  justify-content: center;
  padding: 16px;
}
.FileUploader_fileUploader__P4yeK .FileUploader_imageListing__jN7z3 img {
  height: 150px;
  max-width: 100%;
}
@media screen and (max-width: 820px) {
  .FileUploader_fileUploader__P4yeK .FileUploader_imageListing__jN7z3 img {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/FileUploader/FileUploader.module.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;EACA,aAAA;EACA,eAAA;EACA,eAAA;EACA,kBAAA;EAEA,mBAAA;EAEA,uBAAA;EACA,aAAA;EACA,kBAAA;EACA,yCAAA;AAAJ;AAEE;EACE,kBAAA;EACA,yBAAA;EACA,aAAA;EACA,kBAAA;AAAJ;AACI;EACE,kBAAA;EACA,eAAA;AACN;AACI;EACE,WAAA;EACA,SAAA;EACA,kBAAA;AACN;AAEE;EACE,aAAA;EACA,uBAAA;EACA,aAAA;AAAJ;AACI;EACE,aAAA;EACA,eAAA;AACN;AAEE;EAEI;IACE,WAAA;EADN;AACF","sourcesContent":[".fileUploader {\n  .uploadSection {\n    min-height: 150px;\n    display: flex;\n    flex-wrap: wrap;\n    cursor: pointer;\n    position: relative;\n    -webkit-box-align: center;\n    align-items: center;\n    -webkit-box-pack: center;\n    justify-content: center;\n    padding: 1rem;\n    border-radius: 6px;\n    border: 2px dashed rgba(47, 43, 61, 0.16);\n  }\n  .fileDetails {\n    position: relative;\n    border: 1px solid #dbdade;\n    padding: 10px;\n    border-radius: 5px;\n    .filePreview img {\n      border-radius: 5px;\n      max-width: 100%;\n    }\n    .closeButton {\n      right: 10px;\n      top: 10px;\n      position: absolute;\n    }\n  }\n  .imageListing {\n    display: flex;\n    justify-content: center;\n    padding: 16px;\n    img {\n      height: 150px;\n      max-width: 100%;\n    }\n  }\n  @media screen and (max-width: 820px) {\n    .imageListing {\n      img {\n        width: 100%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileUploader": `FileUploader_fileUploader__P4yeK`,
	"uploadSection": `FileUploader_uploadSection__ZpFzC`,
	"fileDetails": `FileUploader_fileDetails__GDHW3`,
	"filePreview": `FileUploader_filePreview__kIOyp`,
	"closeButton": `FileUploader_closeButton__VzC8m`,
	"imageListing": `FileUploader_imageListing__jN7z3`
};
export default ___CSS_LOADER_EXPORT___;
