const NAVITEMS = [
  {
    icon: "/icons/dashboard.svg",
    title: "Dashboard",
    path: "/",
    name: "dashboard",
    permission: "",
  },
  {
    icon: "/icons/navigation.svg",
    title: "Navigation",
    path: "/navigation",
    name: "navigation",
    permission: "navigation",
  },
  {
    icon: "/icons/pages.svg",
    title: "Pages",
    path: "/pages",
    name: "pages",
    permission: "pages",
  },
  {
    icon: "/icons/addNews.svg",
    title: "Add News",
    path: "/addNews",
    name: "addNews",
    permission: "news",
  },
  {
    icon: "/icons/news.svg",
    title: "News",
    path: "/news",
    name: "news",
    permission: "news",
  },
  {
    icon: "/icons/scheduledPost.svg",
    title: "Scheduled Post",
    path: "/schedulePost",
    name: "schedulePost",
    permission: "schedulePost",
  },
  {
    icon: "/icons/drafts.svg",
    title: "Drafts",
    path: "/drafts",
    name: "drafts",
    permission: "news",
  },
  {
    icon: "/icons/news.svg",
    title: "Breaking News",
    path: "/breaking-news",
    name: "breakingNews",
    permission: "news",
  },
  {
    icon: "/icons/news.svg",
    title: "Top News",
    path: "/top-news",
    name: "topNews",
    permission: "drafts",
  },
  {
    icon: "/icons/youtube.svg",
    title: "Video",
    path: "/youtube",
    name: "youtube",
    permission: "youtube",
  },
  {
    icon: "/icons/categories.svg",
    title: "Categories",
    path: "/categories",
    name: "categories",
    permission: "categories",
  },
  {
    icon: "/icons/comments.svg",
    title: "Comments",
    path: "/comments",
    name: "comments",
    permission: "comments",
  },
  {
    icon: "/icons/messages.svg",
    title: "Messages",
    name: "messages",
    permission: "messages",
    children: [
      {
        title: 'Contact us',
        icon: "/icons/lists.svg",
        path: '/contacts'
      },
      {
        title: 'Grievance',
        icon: "/icons/lists.svg",
        path: '/grievances'
      }
    ]
  },
  {
    icon: "/icons/ads.svg",
    title: "Ads",
    path: "/ads",
    name: "ads",
    permission: "ads",
  },
  {
    icon: "/icons/career.svg",
    title: "Career",
    path: "/career",
    name: "career",
    permission: "career",
  },
  {
    icon: "/icons/users.svg",
    title: "Users",
    path: "/users",
    name: "users",
    permission: "users",
  },
  {
    icon: "/icons/games.svg",
    title: "Games",
    path: "/games",
    name: "games",
    permission: "games",
  },
  {
    icon: "/icons/articles.svg",
    title: "Articles",
    path: "/articles",
    name: "articles",
    permission: "articles",
  },
  {
    icon: "/icons/roles.svg",
    title: "Roles",
    path: "/roles",
    name: "roles",
    permission: "roles",
  },
  {
    icon: "/icons/settings.svg",
    title: "Settings",
    path: "/settings",
    name: "settings",
    permission: "settings",
  },
];

export { NAVITEMS };
