import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardHeader,
  Avatar,
  Link,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const cardColors = {
  primary: "#ffffff",
  secondary: "#7367F0",
  PrimaryAvatar: "rgba(115, 103, 240, 0.2)",
  secondaryAvatar: "rgba(255, 255, 255, 0.2)",
};

function Cards({ data, countData }) {
  return (
    <Grid container spacing={2}>
      {data.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            style={{
              backgroundColor:
                index % 2 === 0 ? cardColors.secondary : cardColors.primary,
              color:
                index % 2 === 0 ? cardColors.primary : cardColors.secondary,
              border: "1px solid #7367F0",
              boxShadow: "none",
            }}
          >
            <CardHeader
              avatar={
                <Avatar
                  sx={{
                    bgcolor:
                      index % 2 === 0
                        ? cardColors.secondaryAvatar
                        : cardColors.PrimaryAvatar,
                    height: "60px",
                    width: "60px",
                  }}
                >
                  <img
                    src={
                      index % 2 === 0
                        ? "/icons/alignLeft.svg"
                        : "/icons/alignLeftBlue.svg"
                    }
                    alt="avatar"
                  />
                </Avatar>
              }
              title={countData?.[item?.key]}
              titleTypographyProps={{ color: index % 2 === 0 ? cardColors.primary : cardColors.secondary }}
              sx={{ fontSize: "37px", lineHeight: "44px" }}
            />
            <CardContent>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontSize: "15px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: index % 2 === 0 ? cardColors.primary : cardColors.secondary,
                  }}
                >{item.title}</Typography>
                <Link
                  sx={{ padding: "10px", textDecoration: "none" }}
                  href={item.path}
                  rel="noopener noreferrer"
                >
                  <ArrowForwardIcon
                    sx={{
                      color:
                        index % 2 === 0
                          ? cardColors.primary
                          : cardColors.secondary,
                    }}
                  />
                </Link>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default Cards;
