import React, { useEffect, useState } from "react";
import TableComponent from "../../components/Table";
import { useFetchScheduledPostsQuery, useDeleteNewsMutation } from '../../features/api/newsApi';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const headers = [
  { title: "#", key: "id", type: "id" },
  { title: "POST", key: "post", type: "imageText" },
  { title: "CATEGORY", key: "name", type: "category" },
  { title: "AUTHOR", key: "name", type: "parent", parent: "author" },
  { title: "VIEWS", key: "views", type: "text" },
  { title: "DATE ADDED", key: "date_time", type: "date" },
];

function ScheduledPost() {
  const navigate = useNavigate();
  const [filterValues, setFilterValues] = useState({});
  const [params, setParams] = useState({ page: 1, perPage: 10 });
  const { data, refetch } = useFetchScheduledPostsQuery(params);
  const userData = useSelector(state => state?.auth?.user);

  useEffect(() => {
    refetch();
  }, [refetch, params]);

  useEffect(() => {
    if (!(userData?.roleData['schedulePost_viewAll'] || userData?.roleData['schedulePost_viewOwn'])) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.roleData]);

  const [deleteNews] = useDeleteNewsMutation();

  const handleEdit = (id) => {
    navigate(`/addNews/${id}`);
  }
  const handleDelete = async (id) => {
    const response = await deleteNews(id);
    refetch();
    if (response?.data?.status) {
      toast.success(response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
    }
  }

  const onSearch = (value) => {
    const updatedParams = { ...params };
    delete updatedParams.filter;
    setParams({ ...updatedParams, search: value, page: 1 })
  }

  const filterSearch = () => {
    setParams({ ...params, filter: encodeURIComponent(JSON.stringify(filterValues)), page: 1 })
  }

  const onPaginationCountChange = (value) => {
    setParams({ ...params, perPage: value })
  }

  const onPaginationChange = (value) => {
    setParams({ ...params, page: value })
  }

  return (
    <div className="container">
      <div className="table-listing">
        <TableComponent
          title="Scheduled Posts"
          defaultTitle={true}
          headers={headers}
          data={data?.data?.list}
          metaData={data?.data?.meta}
          rows={10}
          buttonContent="Add Posts"
          view={false}
          add={true}
          addUrl='/addNews'
          navigateFromBase={true}
          buttonStatus={true}
          actions={[{ type: 'edit', role: 'edit', onClick: handleEdit }, { type: 'delete', role: 'delete', onClick: handleDelete }]}
          permissionPath="schedulePost"
          currentPage={params?.page}
          onPaginationChange={onPaginationChange}
          onSearch={onSearch}
          onPaginationCountChange={onPaginationCountChange}
          filter={true}
          filterData={[
            { title: "Author", type: "author", field: "author" },
            { title: "Category", type: "category", field: "category" },
            { title: "Sub Category", type: "subcategory", field: "subcategory" },
            { title: "Date", type: 'date', field: "date" },
          ]}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          filterSearch={filterSearch}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default ScheduledPost;
