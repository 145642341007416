import React, { useEffect, useState } from 'react';
import TableComponent from "../../components/Table";
import { useFetchAdvertisementQuery, useDeleteAdvertisementMutation } from '../../features/api/advertisementApi';
import { useNavigate } from 'react-router-dom';
import { AD_SPACES } from "../../utils";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const headers = [
  { title: "#", key: "id", type: "id" },
  { title: "AD SPACE", key: "ad_space", type: "filter", array: AD_SPACES },
  { title: "AD CODE", key: "name", type: "text" },
  { title: "AD IMAGE", key: "image", type: "adImage" },
  { title: "AD CATEGORY", key: 'category', type: 'parent' },
  { title: "AD SUB CATEGORY", key: 'subcategory', type: 'category' },
];

function Ads() {
  const navigate = useNavigate();
  const [filterValues, setFilterValues] = useState({});
  const [params, setParams] = useState({ page: 1, perPage: 10 });
  const { data, refetch } = useFetchAdvertisementQuery(params);
  const userData = useSelector(state => state?.auth?.user);

  useEffect(() => {
    refetch();
  }, [refetch, params]);

  useEffect(() => {
    if (!userData?.roleData['ads_viewAll']) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.roleData]);

  const [deleteAdvertisement] = useDeleteAdvertisementMutation();

  const handleEdit = (id) => {
    navigate(`/ads/edit/${id}`);
  }
  const handleDelete = async (id) => {
    const response = await deleteAdvertisement(id);
    refetch();
    if (response?.data?.status) {
      toast.success(response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
    }
  }

  const onSearch = (value) => {
    const updatedParams = { ...params };
    delete updatedParams.filter;
    setParams({ ...params, search: value, page: 1 })
  }

  const filterSearch = () => {
    setParams({ ...params, filter: encodeURIComponent(JSON.stringify(filterValues)), page: 1 })
  }

  const onPaginationCountChange = (value) => {
    setParams({ ...params, perPage: value })
  }

  const onPaginationChange = (value) => {
    setParams({ ...params, page: value })
  }

  return (
    <div className="container">
      <div className="table-listing">
        <TableComponent
          title="Advertisements"
          defaultTitle={true}
          headers={headers}
          data={data?.data?.list}
          metaData={data?.data?.meta}
          rows={10}
          buttonContent="New"
          view={false}
          add={true}
          buttonStatus={true}
          actions={[{ type: 'edit', role: 'edit', onClick: handleEdit }, { type: 'delete', role: 'delete', onClick: handleDelete }]}
          permissionPath="ads"
          currentPage={params?.page}
          onPaginationChange={onPaginationChange}
          onSearch={onSearch}
          onPaginationCountChange={onPaginationCountChange}
          filter={true}
          filterData={[
            { title: "Ad Space", type: "ad_space", field: "ad_space" },
            { title: "Category", type: "category", field: "category" },
            { title: "Sub Category", type: "subcategory", field: "subcategory" },
          ]}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          filterSearch={filterSearch}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default Ads;