import React from "react";

export default function editicon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.81473 2.37054H2.29216C1.94946 2.37054 1.62079 2.50668 1.37847 2.74901C1.13614 2.99134 1 3.32 1 3.66271V12.7078C1 13.0505 1.13614 13.3792 1.37847 13.6215C1.62079 13.8639 1.94946 14 2.29216 14H11.3373C11.68 14 12.0087 13.8639 12.251 13.6215C12.4933 13.3792 12.6295 13.0505 12.6295 12.7078V8.18527M11.6603 1.40142C11.9174 1.1444 12.266 1 12.6295 1C12.9929 1 13.3415 1.1444 13.5986 1.40142C13.8556 1.65845 14 2.00705 14 2.37054C14 2.73404 13.8556 3.08264 13.5986 3.33967L7.46081 9.47743L4.87649 10.1235L5.52257 7.53919L11.6603 1.40142Z"
        stroke="#4C5BE0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
