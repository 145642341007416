// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user_userDetails__b6GsM {
  padding: 15px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.user_userSubmit__NSKG8 {
  display: flex;
}

.user_socialHeading__k-8xv {
  text-align: left;
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .user_userDetails__b6GsM {
    padding: 0;
    display: block;
  }
  .user_userSubmit__NSKG8 {
    margin-bottom: 15px;
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/users/user.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,aAAA;EACA,qCAAA;EACA,SAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,gBAAA;EACA,mBAAA;AACJ;;AAEA;EACI;IACI,UAAA;IACA,cAAA;EACN;EACE;IACI,mBAAA;IACA,cAAA;EACN;AACF","sourcesContent":[".userDetails {\n    padding: 15px 0;\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 15px;\n}\n\n.userSubmit {\n    display: flex;\n}\n\n.socialHeading {\n    text-align: left;\n    margin-bottom: 10px;\n}\n\n@media only screen and (max-width: 600px) {\n    .userDetails {\n        padding: 0;\n        display: block;\n    }\n    .userSubmit {\n        margin-bottom: 15px;\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userDetails": `user_userDetails__b6GsM`,
	"userSubmit": `user_userSubmit__NSKG8`,
	"socialHeading": `user_socialHeading__k-8xv`
};
export default ___CSS_LOADER_EXPORT___;
