import React from "react";
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="footer-section">
      <hr />
      <div className="footer-content">
        <Link to="/">Home</Link>
        <p>Copyright © 2023 Nattuvartha All Rights Reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
